import { SessionLoggedOutComponent } from './components/session-logged-out.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    SessionLoggedOutComponent
  ],
  exports: [
    SessionLoggedOutComponent,
  ]
})
export class SessionLoggedOutModule {
}
