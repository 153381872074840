import { NgModule } from '@angular/core';

import { HeaderComponent } from './header.component';
import { BannerImageModule } from '../banner-image/banner-image.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    BannerImageModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule {
}
