import { HaroProvider } from '../shared/services/haro.service';
import { TenantsService } from '../services/tenants.service';
import { Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { ConfigProvider, ConfigToken } from '../config/config';
import { WindowProvider } from '../shared/services/window';

/*
 * Provides the singleton services that load when the application starts.
 * CoreModule is a pure services module with no declarations.
 * Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
*/
@NgModule({
  imports: [
    AuthModule.forRoot(),
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private injector: Injector,
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

  // https://angular.io/guide/ngmodule-faq#what-is-the-forroot-method
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        HaroProvider,
        TenantsService,
        ConfigProvider,
        WindowProvider,
      ]
    };
  }

}
