import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { ConfirmOptions } from '../../../shared/model/dialog-options';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmOptions,
              public dialogRef: MatDialogRef<ConfirmDialogComponent>){ }

  ngOnInit(): void {
  }

  confirm() {
    // this response is captured by the DialogService
    this.dialogRef.close('confirm');
  }

  cancel() {
    // this response is captured by the DialogService
    this.dialogRef.close('cancel');
  }
}
