import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpService } from '../../../services/HttpService';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DialogService } from '../../../shared/services/dialog.service';
import { PROGRESS_DIALOG_MESSAGES } from '../partitions.config';

@Injectable()
export class PartitionService {

  apiUrl = 'partitions';
  clientsApiUrl = 'clients';
  bundleApiUrl = 'bundle';

  constructor(private httpService: HttpService,
              private dialogService: DialogService) {
  }

  getPartitions(): Observable<any> {
    return this.httpService.get(this.apiUrl)
      .pipe(
        catchError(this.httpService.handleForbiddenAccess)
      );
  }

  postPartition(partition: any): Observable<any> {
    return this.httpService.post(this.apiUrl, partition)
      .pipe(
        catchError(this.httpService.handleForbiddenAccess)
      );
  }

  deletePartition(id: string): Observable<any> {
    return this.httpService.delete(`${this.apiUrl}/${id}`)
      .pipe(
        catchError(this.httpService.handleForbiddenAccess)
      );
  }

  getPartitionClients(id: string): Observable<any> {
    const partitionClientsUrl = `${this.apiUrl}/${id}/${this.clientsApiUrl}`;
    return this.httpService.get(partitionClientsUrl)
      .pipe(
        catchError(this.httpService.handleForbiddenAccess)
      );
  }

  postPartitionClient(client: any): Observable<any> {
    return this.httpService.post(this.clientsApiUrl, client)
      .pipe(
        catchError(this.httpService.handleForbiddenAccess)
      );
  }

  getPartitionDetails(id: string): Observable<any> {
    const partitionUrl = `${this.apiUrl}/${id}`;
    return this.httpService.get(partitionUrl)
      .pipe(
        catchError(this.httpService.handleForbiddenAccess)
      );
  }

  deleteClient(id: string): Observable<any> {
    return this.httpService.delete(`${this.clientsApiUrl}/${id}`)
      .pipe(
        catchError(this.httpService.handleForbiddenAccess)
      );
  }

  downloadClient(bundle: any): Observable<any> {
    return this.httpService.postForFile(this.bundleApiUrl, bundle);
  }

  /**
   * Helper method used in multiple components to open the confirmation dialog when user tries to delete a partition
   * The service call to delete partition is taken care by this method.
   * The calling component only needs to handle the success or failure response returned by this method
   *
   * @param partition
   */
  openConfirmDeletePartition(partition) {
    const { name, serialNumber} = partition;
    let progressDialogRef;
    const userAction$ = this.dialogService.openDeletePartition({ partitionName: name });
    return userAction$.pipe(
      switchMap((action) => {
        if (action === 'delete') {
          // The progressDialogRef is purposefully not closed by this service
          // it should be closed by the caller of this method so that the `Deleting Partition...` dialog gets closed
          // only after reloading the partition list
          progressDialogRef = this.dialogService.progress({text: PROGRESS_DIALOG_MESSAGES.DELETE_PARTITION});
          return this.deletePartition(serialNumber);
        }
        // send an empty Observable when user clicks on Cancel
        // so that the below code block doesn't throw console error
        return of(null);
      }),
      map(res => {
        return { res, progressDialogRef};
      }),
      catchError(err => {
        progressDialogRef.close();
        this.dialogService.error(err);
        return of(null);
      })
    );
  }
}
