<div class="details-panel">
  <div class="details-panel-section">
    <div class="review-row">
      <div>Partition Name</div>
      <div name="partition-name">
        <strong>{{partitionName}}</strong>
      </div>
    </div>
    <div class="review-row">
      <div>{{fipsRestrictions}}</div>
      <div name="fips-restriction">
        <strong>{{deviceType | fips}}</strong>
      </div>
    </div>
  </div>
</div>
