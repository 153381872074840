<h5 class="dialog-title">Delete Partition?</h5>
<div class="alert alert-danger" role="alert">
  <svg class="icon-svg">
    <use xlink:href="#warning" class="alert-text" />
  </svg>
  <strong class="warning-text">Warning! This is an unrecoverable operation!</strong>
</div>
<div class="delete-instructions">
  <span>Deleting partition <strong>"{{partitionName}}"</strong> will destroy all crypto material
        and client connections associated with this partition</span>
</div>
<div class="delete-instructions">
  <span>Please confirm by entering this partition's name</span>
</div>
<div>
  <label for="partitionName" class="control-label">Partition Name</label>
  <input id="partitionName" name="partitionName" class="form-control"
         #partitionNameField="ngModel"
         [(ngModel)]="enteredName"
         required autofocus>
  <div *ngIf="partitionNameField.dirty" id="error-message-partitionName" class="text-danger error-message" role="alert">
    <div *ngIf="!isValidName()">Please enter the correct partition name</div>
  </div>
</div>

<div class="no-gutters">
    <button class="btn btn-next" name="delete" type="button"
            (click)="btnAction('delete')"
            [disabled]="!isValidName()">Delete</button>
    <button class="btn btn-default" name="cancel" type="button" (click)="btnAction('cancel')">Cancel</button>
</div>
