import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'validation-icon',
  templateUrl: './validation-icon.component.html',
  styleUrls: ['./validation-icon.component.scss']
})
export class ValidationIconComponent implements OnInit {
  @Input() configForm;

  constructor() { }

  ngOnInit(): void {
  }

}
