import { WizardStep } from '../../shared/model/wizard-step';
// these steps are shown while creating a new partition
export const CREATE_PARTITION_STEPS: WizardStep[] = [{
  stepIndex: 0,
  title: 'Configure',
  instructions: 'Configure your partition',
  mainInstructions: 'Users with access to this subscriber group can manage this partition'
}, {
  stepIndex: 1,
  title: 'Review',
  instructions: 'Review your configuration',
  mainInstructions: 'Click the "Finish" button to create this partition'
}];

export enum DEVICE_TYPE {
  'cryptovisor' = 'cryptovisor',
  'cryptovisor_fips' = 'cryptovisor_fips',
}

export const UI_TEXT = {
  FIPS_RESTRICTIONS : 'FIPS Restrictions'
};

export const PARTITION_LIST_TB_COLS = ['name', 'serialNumber', 'deviceType', 'createdAt', 'createdBy', 'actions'];

export const PARTITION_ROUTES = {
  basePath : 'partitions'
};

export const PROGRESS_DIALOG_MESSAGES = {
  LOADING : 'Loading...',
  CREATE_PARTITION: 'Preparing Partition...',
  DELETE_PARTITION : 'Deleting Partition...',
  DELETE_PARTITION_CLIENT: 'Deleting Partition Client...',
  CREATE_PARTITION_CLIENT: 'Creating Partition Client...',
  DOWNLOAD_PARTITION_CLIENT: 'Downloading Partition Client...'
};

export const TOAST_MESSAGES = {
  PARTITION_CREATED: 'Partition created successfully!',
  PARTITION_DELETED: 'Partition deleted successfully!',
  PARTITION_CLIENT_DELETED: 'Partition client deleted successfully!',
  PARTITION_CLIENT_CREATED: 'Partition client created successfully!',
  PARTITION_CLIENT_DOWNLOADED: 'Partition client downloaded successfully!'
};
