<div *ngIf="partitionDetails" class="gem-header section-header">
  <a class="gem-link partition-header" (click)="goToPartitions()">HSM Partitions > </a> <span>{{partitionDetails.name}} </span>
  <div class="delete-btn-area">
    <button class="btn btn-next" type="button" (click)="openConfirmDelete()">Delete Partition</button>
  </div>
</div>

<div *ngIf="partitionDetails" class="flex-container margin box-shadow">
  <div class="details">
    <div>Name</div>
    <div class="font-weight-bold">{{partitionDetails.name}}</div>
  </div>
  <div class="details">
    <div>Partition Serial No.</div>
    <div class="font-weight-bold">{{partitionDetails.serialNumber}}</div>
  </div>
  <div class="details">
    <div>{{fipsRestrictions}}</div>
    <div class="font-weight-bold">{{partitionDetails.deviceType | fips}}</div>
  </div>
  <div class="details">
    <div>Created</div>
    <div class="font-weight-bold">{{partitionDetails.createdAt | formatDate}}</div>
  </div>
  <div class="details">
    <div>Created by</div>
    <div class="font-weight-bold">{{partitionDetails.createdBy}}</div>
  </div>
</div>

<ng-container>
  <partition-clients [serialNumber]="serialNumber"></partition-clients>
</ng-container>
