import { Pipe, PipeTransform } from '@angular/core';
import { DEVICE_TYPE } from '../../features/partitions/partitions.config';

/**
 * This pipe is used to display the text in the UI for FIPS Restrictions column/field
 * It should display Enabled if the device type is cryptovisor_fips
 * It should display Disabled otherwise(i.e. device type === cryptovisor)
 */

@Pipe({
  name: 'fips'
})
export class FipsPipe implements PipeTransform {

  transform(value: string): string {
    return value === DEVICE_TYPE.cryptovisor_fips ? 'Enabled' : 'Disabled';
  }
}
