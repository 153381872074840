import { ModuleWithProviders, NgModule } from '@angular/core';
import { TenantsService } from './tenants.service';

@NgModule({
  providers: [
    TenantsService
  ]
})
export class TenantsModule {

  // static forRoot(): ModuleWithProviders<any> {
  //   return {
  //     ngModule: TenantsModule,
  //     providers: [
  //       TenantsService
  //     ],
  //   };
  //
  // }
}
