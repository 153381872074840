import { Component, OnInit } from '@angular/core';
import { PortalLink } from '../../shared/model/portal-links';
// @ts-ignore
import * as portalLinks from '../../../assets/portal-links.json';

@Component({
  selector: 'gem-help-drawer',
  templateUrl: './help-drawer.component.html',
  styleUrls: ['./help-drawer.component.scss']
})
export class HelpDrawerComponent implements OnInit {
  helpVisible = false;
  readonly links: PortalLink[] = portalLinks.links;

  constructor() { }

  ngOnInit(): void {
  }

  toggleVisible(): void {
    this.helpVisible = !this.helpVisible;
  }

  hasLinks(): boolean {
    return this.links.length > 0;
  }

}
