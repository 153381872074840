import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { PartitionService } from '../../services/partition.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { HsmodTableComponent } from '../../../../components/hsmod-table/hsmod-table.component';
import { DialogService } from '../../../../shared/services/dialog.service';
import { CreateClientDialogComponent } from '../create-client-dialog/create-client-dialog.component';
import { DownloadClientDialogComponent } from '../download-client/download-client-dialog.component';
import { PROGRESS_DIALOG_MESSAGES, TOAST_MESSAGES } from '../../partitions.config';
import { ToastService } from '../../../../shared/services/toast.service';
// @ts-ignore
import * as portalLinks from '../../../../../assets/portal-links.json';

@Component({
  selector: 'partition-clients',
  templateUrl: './partition-clients.component.html',
  styleUrls: ['./partition-clients.component.scss']
})
export class PartitionClientsComponent implements OnInit, AfterViewInit {
  @Input() serialNumber;
  partitionClients;
  public isProcessing: boolean;
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'clientId', 'createdAt', 'createdBy', 'actions'];
  readonly clientsHelp = portalLinks.helpLinks.find(link => link.id === 'clientsHelp');

  constructor(public partitionService: PartitionService,
              private toastService: ToastService,
              public dialogService: DialogService) {}

  @ViewChild(HsmodTableComponent) private hsmodTable: HsmodTableComponent;
  @ViewChild(MatSort) private sort: MatSort;

  ngOnInit(): void {
    this.isProcessing = true;
    this.getPartitionClients();
  }

  isEmpty(): boolean {
    return !this.partitionClients || this.partitionClients.length === 0;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    if (this.hsmodTable) {
      this.dataSource.paginator = this.hsmodTable.paginator;
    }
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

  openConfirmDelete(clientId) {
    const userAction$ = this.dialogService.confirm({
      title: 'Delete Partition Client?',
      message: 'This client\'s access to your partition will be revoked and will no longer be able to access the partition.',
      confirmLabel: 'Delete',
      cancelLabel: 'Cancel'
    });
    userAction$.subscribe(action => {
      if (action === 'confirm'){
        const progressDialogRef = this.dialogService.progress({text: PROGRESS_DIALOG_MESSAGES.DELETE_PARTITION_CLIENT});
        this.partitionService.deleteClient(clientId).subscribe(clients => {
          if (clients && clients.status === 204) {
            this.getPartitionClients();
            progressDialogRef.close();
            this.toastService.success(TOAST_MESSAGES.PARTITION_CLIENT_DELETED);
          } else {
            progressDialogRef.close();
            this.dialogService.error(clients);
          }
        }, err => {
          progressDialogRef.close();
          this.dialogService.error(err);
        });
      }
    });
  }

  /** When the user clicks on 'Create Partition Client' button, it should open the Create Partition Client Dialog
   */
  openCreatePartitionClientDialog() {
    const clientData = {
      serialNumber: this.serialNumber,
      actionText: 'Create Partition Client'
    };
    const dialogRef: any = this.dialogService.open(CreateClientDialogComponent, '400px', clientData);

    dialogRef.componentInstance.onCreate.subscribe((data) => {
      this.getPartitionClients();
      const clientDownloadData = {
        actionText: 'Download Client',
        clientName: data.clientName,
        clientId: data.clientId,
        clientSecret: data.clientSecret
      };
      this.dialogService.open(DownloadClientDialogComponent, '400px', clientDownloadData);
    });
  }

  getPartitionClients() {
    const progressDialogRef  = this.dialogService.progress({text: PROGRESS_DIALOG_MESSAGES.LOADING});
    this.partitionService.getPartitionClients(this.serialNumber).subscribe(res => {
      if (res && res.status === 200) {
        this.partitionClients = res.body;
        this.dataSource.data = this.partitionClients || [];
        if (this.hsmodTable) {
          this.dataSource.paginator = this.hsmodTable.paginator;
        }
        this.isProcessing = false;
        progressDialogRef.close();
      } else {
        this.isProcessing = false;
        progressDialogRef.close();
        this.dialogService.error(res);
      }
    }, err => {
      this.isProcessing = false;
      progressDialogRef.close();
      this.dialogService.error(err);
    });
  }
}
