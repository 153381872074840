import { Component, EventEmitter, OnInit, Output } from '@angular/core';
// @ts-ignore
import * as portalLinks from '../../../../../assets/portal-links.json'
@Component({
  selector: 'empty-partition',
  templateUrl: './empty-partition.component.html',
  styleUrls: ['./empty-partition.component.scss']
})
export class EmptyPartitionComponent implements OnInit {
  @Output() createEvent = new EventEmitter();
  readonly partitionHelp = portalLinks.helpLinks.find(link => link.id === 'partitionHelp');

  constructor() {
  }

  ngOnInit(): void {
  }

  openDialog() {
    this.createEvent.emit();
  }
}
