import { Component, OnInit } from '@angular/core';
import { WizardStep } from '../../../../shared/model/wizard-step';
import {
  CREATE_PARTITION_STEPS,
  DEVICE_TYPE,
  PARTITION_ROUTES,
  PROGRESS_DIALOG_MESSAGES, TOAST_MESSAGES
} from '../../partitions.config';
import { MatDialogRef } from '@angular/material';
import { PartitionService } from '../../services/partition.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { Router } from '@angular/router';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'create-partition-dialog',
  templateUrl: './create-partition-dialog.component.html',
  styleUrls: ['./create-partition-dialog.component.scss']
})
export class CreatePartitionDialogComponent implements OnInit{
  // this index indicates which step the user currently views
  currentStepIdx = 0;
  partitionName = '';
  deviceType = DEVICE_TYPE.cryptovisor_fips;
  isCurrentStepValid = false;
  currentStepInfo;
  readonly createPartitionSteps: WizardStep[] = CREATE_PARTITION_STEPS;

  constructor(public dialogRef: MatDialogRef<CreatePartitionDialogComponent>,
              private partitionService: PartitionService,
              private dialogService: DialogService,
              private toastService: ToastService,
              private router: Router) { }

  ngOnInit(){
    this.setCurrentStep();
  }
  /** This method retrieves all the steps names needed to display in the Steps header
   *  returns array of string - Step Names
   */
  getStepNames(): string[]{
    return this.createPartitionSteps.map(step => step.title);
  }

  /** This method sets the right information for the current step based on the step index
   *  If no step index is passed, it is considered as 0
   */
  setCurrentStep(stepIdx?: number){
    this.currentStepIdx = stepIdx || 0;
    this.currentStepInfo =  this.currentStepIdx < this.createPartitionSteps.length ?
              this.createPartitionSteps[this.currentStepIdx] : null;
  }

  /**
   * This method handles the event changes for partition name emitted by the child component
   * @param event
   */
  setPartitionName(event: any): void{
    // uses Object destructuring for brevity
    ({name: this.partitionName, isValid: this.isCurrentStepValid} = event);
  }

  /**
   * This method handles the event changes for FIPS restriction checkbox emitted by the child component
   * @param event
   */
  setDeviceType(event: any): void{
    // uses Object destructuring for brevity
    this.isCurrentStepValid = event.isValid;
    this.deviceType = event.isFIPSRestricted ? DEVICE_TYPE.cryptovisor_fips : DEVICE_TYPE.cryptovisor;
  }

  isLastSlide() {
    return this.currentStepIdx === this.createPartitionSteps.length - 1;
  }

  nextLabel() {
    return this.isLastSlide() ? 'Finish' : 'Next';
  }

  gotoStep(newStepIdx: number) {
    this.setCurrentStep(newStepIdx);
    // if the user clicks "Finish", it should call the API to post the new partition
    if (this.currentStepIdx >= this.createPartitionSteps.length){
      this.createPartition();
      this.closeDialog();
    }
  }

  createPartition() {
    const progressDialogRef = this.dialogService.progress({text: PROGRESS_DIALOG_MESSAGES.CREATE_PARTITION});
    this.partitionService
      .postPartition({name: this.partitionName, deviceType: this.deviceType})
      .subscribe(res => {
        // expectation is after the success response, the user should navigate to the newly created partition
        if (res && res.status === 201){
          const { body: resData } = res;
          if (resData) {
            const { serialNumber } = resData;
            const partitionDetailsRoute = `/${PARTITION_ROUTES.basePath}/${serialNumber}`;
            this.toastService.success(TOAST_MESSAGES.PARTITION_CREATED);
            this.router.navigate([partitionDetailsRoute]);
          }
        } else {
          this.dialogService.error(res);
        }
        progressDialogRef.close();
      }, err => {
        progressDialogRef.close();
        this.dialogService.error(err);
      });
  }

  closeDialog(){
    this.dialogRef.close();
  }

  isFIPSRestricted(){
    return this.deviceType === DEVICE_TYPE.cryptovisor_fips;
  }
}
