<h4 class="dialog-title">Create Partition</h4>
<!-- displays the steps header -->
<wizard-steps [steps]="getStepNames()" [currentStepIdx]="currentStepIdx"></wizard-steps>
<!-- current step Instructions -->
<div class="wizard-content">
  <div>
    <div class="step-instructions" *ngIf="currentStepInfo">
      <span>Step {{currentStepIdx + 1}} : </span>
      <span>{{currentStepInfo.instructions}}</span>
    </div>

    <div class="main-step-instructions" *ngIf="currentStepInfo">
      <span>{{currentStepInfo.mainInstructions}}</span>
    </div>
  </div>
  <!-- dynamic step screen content -->
  <ng-container *ngIf="currentStepIdx === 0">
    <configure-partition [partitionName]="partitionName"
                         [isFIPSRestricted]="isFIPSRestricted()"
                         (nameChangeEvent)="setPartitionName($event)"
                         (deviceTypeEvent)="setDeviceType($event)"></configure-partition>
  </ng-container>
  <ng-container *ngIf="currentStepIdx === 1">
    <review-partition [partitionName]="partitionName"
                      [deviceType]="deviceType"></review-partition>
  </ng-container>
</div>
<!-- wizard footer buttons -->
<wizard-footer [currentStepIdx]="currentStepIdx"
               [isCurrentStepValid]="isCurrentStepValid"
               [nextLabel]="nextLabel()"
               (cancelEvent)="closeDialog()"
               (backEvent)="gotoStep(currentStepIdx - 1)"
               (nextEvent)="gotoStep(currentStepIdx + 1)"></wizard-footer>
