<aside class="fixed-side-menu" id="side-menu-wrapper" [ngClass]="{
    'is-open': helpVisible,
    'empty': !hasLinks()
}">
  <button class="fixed-side-menu--trigger" (click)="toggleVisible()" aria-label="Toggle context-sensitive help">
    <svg class="question" version="1.1" x="0px" y="0px" viewBox="0 0 32 32" enable-background="new 0 0 32 32">
      <use xlink:href="#question-round"></use>
    </svg>
  </button>

  <section class="fixed-side-menu--slideout">
    <div *ngIf="links.length" class="on-this-page mb-4">
      <p class="mb-1">On this page</p>
      <ul>
        <li *ngFor="let link of links">
          <a href="{{ link.url | docLink }}" target="_blank" tabindex="{{ helpVisible ? 0 : -1 }}" externalLink>{{link.title}}</a>
        </li>
      </ul>
    </div>

    <p class="mb-1">Luna Cloud HSM</p>
    <ul>
      <li><a href="{{ '/dpod/' | docLink }}" target="_blank" externalLink>Full documentation</a></li>
    </ul>
  </section>
</aside>
