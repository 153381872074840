import { Pipe, PipeTransform } from '@angular/core';
import { DocsService } from '../services/docs.service';

/**
 * Send a string to this pipe and it will be turned into an absolute link to the
 * documentation website.
 *
 * If the URL exists in portal-links.json, consider using the docLinkById pipe
 *
 * Example:
 * ```html
 *  <a href="{{ '/dpod/services/hsmod_services/' | docLink }}">Click here for more info</a>
 * ```
 *
 * will return the `DOCS_BASE_URL + /dpod/services/hsmod_services/`
 * ex. https://thalesdocs.com/dpod/services/hsmod_services/
 */

@Pipe({
  name: 'docLink'
})
export class DocLinkPipe implements PipeTransform {

  constructor(private docsService: DocsService) {
  }
  transform(value: string): string {
    return this.docsService.getDocsLink(value);
  }

}
