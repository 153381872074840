import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PartitionsComponent } from './features/partitions/components/partitions.component';
import { PreLoginComponent } from './features/prelogin/components/prelogin.component';
import { PartitionDetailsComponent } from './features/partitions/components/partition-details/partition-details.component';

// https://angular.io/tutorial/toh-pt5
const routes: Routes = [
  { path: 'partitions', component: PartitionsComponent },
  { path: 'prelogin', component: PreLoginComponent }, // Remove the prelogin page once UAA is gotten rid of
  { path: 'partitions/:serial_number', component: PartitionDetailsComponent },
  { path: '**',   redirectTo: 'partitions'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
