import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '../components/header/header.module';
import { GemIconsModule } from '../components/gem-icons/gem-icons.module';
import { HelpDrawerComponent } from '../components/help-drawer/help-drawer.component';
import { HsmodTableComponent } from '../components/hsmod-table/hsmod-table.component';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import { ProgressComponent } from '../components/dialogs/progress/progress.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { DocLinkPipe } from './pipes/doc-link.pipe';
import { FooterModule } from '../components/footer/footer.module';
import { HttpService } from '../services/HttpService';
import { DialogService } from './services/dialog.service';
import { PartitionService } from '../features/partitions/services/partition.service';
import { WizardStepsComponent } from '../components/wizard-steps/wizard-steps.component';
import { WizardFooterComponent } from '../components/wizard-footer/wizard-footer.component';
import { ValidationIconComponent } from '../components/validation-icon/validation-icon.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { FormsModule } from '@angular/forms';
import { ClientFooterComponent } from '../components/client-footer/client-footer.component';
import { ToastrModule } from 'ngx-toastr';
import { ToastComponent } from '../components/toast/toast.component';
import { FipsPipe } from './pipes/fips.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { ErrorDialogComponent } from '../components/dialogs/error-dialog/error-dialog.component';
import { ErrorPageComponent } from '../components/error-page/error-page.component';

@NgModule({
  declarations: [
    HelpDrawerComponent,
    DocLinkPipe,
    WizardStepsComponent,
    WizardFooterComponent,
    ClientFooterComponent,
    ValidationIconComponent,
    HsmodTableComponent,
    ConfirmDialogComponent,
    ProgressComponent,
    ErrorDialogComponent,
    ErrorPageComponent,
    SpinnerComponent,
    ToastComponent,
    DocLinkPipe,
    FipsPipe,
    FormatDatePipe
  ],
  imports: [
    CommonModule,
    HeaderModule,
    GemIconsModule,
    FooterModule,
    AngularMaterialModule,
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      toastClass: `ngx-toastr`,
      timeOut: 7000,
      extendedTimeOut: 0,
      tapToDismiss: false,
      toastComponent: ToastComponent,
    })
  ],
  exports: [
    HelpDrawerComponent,
    HsmodTableComponent,
    WizardStepsComponent,
    WizardFooterComponent,
    ClientFooterComponent,
    ConfirmDialogComponent,
    ProgressComponent,
    SpinnerComponent,
    ToastComponent,
    DocLinkPipe,
    HeaderModule,
    GemIconsModule,
    FooterModule,
    ValidationIconComponent,
    AngularMaterialModule,
    FipsPipe,
    FormatDatePipe,
    ErrorPageComponent
  ],
  providers: [
    HttpService,
    PartitionService,
    DialogService
  ],
  entryComponents: [ToastComponent]
})
export class SharedModule { }
