<form #configForm="ngForm">
  <div class="details-panel">
    <div class="details-panel-section">
      <div>
        <label for="partitionClientName" class="control-label">Partition Client Name</label>
        <div class="input-textbox-container">
          <input id="partitionClientName" #partitionClientNameField="ngModel" name="partitionClientName" class="form-control text-padding" [pattern]="clientNamePattern"
                 minlength="1"
                 [maxlength]="nameMaxLength"
                 [(ngModel)]="partitionClientName" required
                 (input)="nameChange(configForm.valid)"
                 (keydown)="onKeyDown()"
                 (keyup)="onKeyUp()"
                 trim="blur"
                 autofocus>
          <validation-icon [configForm]="configForm"></validation-icon>
        </div>
        <div *ngIf="partitionClientNameField.dirty || maxCharReached" id="error-message-partitionClientName" class="text-danger error-message"
             role="alert">
          <div *ngIf="partitionClientNameField.errors?.pattern">Invalid client name. Only alphanumeric characters, dashes and underscores are allowed.</div>
          <div *ngIf="partitionClientNameField.errors?.required">You may enter alphanumeric characters, dashes and underscores</div>
          <div *ngIf="maxCharReached">Maximum {{nameMaxLength}} characters</div>
        </div>
      </div>
    </div>
  </div>
</form>
