import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { HsmodUiConfig } from '../../../core/hsmod-ui-config';
import { ConfigToken } from '../../../config/config';
import { Router } from '@angular/router';
import { PARTITION_ROUTES, PROGRESS_DIALOG_MESSAGES} from '../../partitions/partitions.config';
import { DialogService } from '../../../shared/services/dialog.service';

// Remove the prelogin page once UAA is gotten rid of
@Component({
  selector: 'prelogin',
  templateUrl: './prelogin.component.html',
  styleUrls: ['./prelogin.component.scss']
})
export class PreLoginComponent {
  @ViewChild('dPoDUrl') dPoDUrl: ElementRef;
  DPOD_URL_HOST = 'DPoD URL/Hostname';
  INVALID_URL_HOSTNAME = `Please provide a valid ${this.DPOD_URL_HOST}`;
  errMessage: string;
  constructor(public authService: AuthService, private cookieService: CookieService, private router: Router,
              @Inject(ConfigToken) public config: HsmodUiConfig, private dialog: DialogService) {
    if (authService.getToken() !== undefined && authService.getToken() !== null) {
      this.router.navigate([`${PARTITION_ROUTES.basePath}`]);
    }
  }

  login() {
    const progressDialogRef = this.dialog.progress({text: 'Redirecting...'});
    const tenantUrl = this.dPoDUrl.nativeElement.value;
    if (!tenantUrl) {
      this.errMessage = this.INVALID_URL_HOSTNAME;
      return;
    }
    let subDomain = this.getHostName(tenantUrl);

    if (subDomain == null) {
      subDomain = tenantUrl.split('.')[0];
    }

    const uaaUrl = this.constructUAAUrl(subDomain);

    this.authService.validateUrl(uaaUrl).subscribe(res => {
      progressDialogRef.close();
      this.onSuccess(res, uaaUrl);
    }, err => {
      progressDialogRef.close();
      this.handleError(err);
    });
  }

  onSuccess(data, uaaUrl) {
    this.cookieService.set('tenant.metadata', JSON.stringify({ url: uaaUrl}));
    this.authService.login(uaaUrl);
  }

  handleError(error) {
    this.errMessage = this.INVALID_URL_HOSTNAME;
  }

  getHostName(url) {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2].split('.')[0];
    }
    else {
      return null;
    }
  }

  constructUAAUrl(subDomain) {
    return `https://${subDomain}.${this.config.CF_UAA_DOMAIN}`;
  }
}
