<div class="buttons-container">
  <div class="buttons-left-section">
    <button class="btn btn-default" name="cancel" type="button" (click)="cancelEvent.emit()">Cancel</button>
  </div>
  <div>
    <div>
      <button *ngIf="currentStepIdx" class="btn btn-default" name="previous" type="button"
              (click)="backEvent.emit()">Go Back
      </button>
      <button id="wizNext" class="btn btn-next" name="next" type="button" [disabled]="!isCurrentStepValid"
              (click)="nextEvent.emit()">{{nextLabel}}
      </button>
    </div>
  </div>
</div>
