import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'configure-partition',
  templateUrl: './configure-partition.component.html',
  styleUrls: ['./configure-partition.component.scss']
})
export class ConfigurePartitionComponent implements OnInit {
  allowNonFips: boolean;
  readonly nameMaxLength = 45;
  @Input() partitionName = '';
  @Input() isFIPSRestricted;
  @Output() nameChangeEvent = new EventEmitter();
  @Output() deviceTypeEvent = new EventEmitter();
  // variables that will help us in showing error message when maxLength is reached
  keyDownLen: number;
  keyUpLen: number;
  maxCharReached: boolean;
  partitionNamePattern = /^[a-zA-Z0-9@_\s]{1,45}$/;
  constructor() { }

  ngOnInit(): void {
    // this screen requires the inverse logic because of the verbiage in UI and checkbox functionality
    this.allowNonFips = !this.isFIPSRestricted;
  }

  /**
   * The changes to the partition name field is updated to the parent component
   * @param isFormValid
   */
  nameChange(isFormValid: boolean) {
    this.nameChangeEvent.emit({name: this.partitionName, isValid: isFormValid});
  }

  onKeyDown(){
    this.keyDownLen = this.partitionName.length;
  }

  onKeyUp(){
    this.keyUpLen = this.partitionName.length;
    // after key up, if the length of the partition name during key down and key up are equal and if we have reached the maxLength allowed
    // show the error message
    this.maxCharReached = this.partitionName.length === this.nameMaxLength && this.keyUpLen === this.keyDownLen;
  }

  /**
   * The changes to the FIPS Restriction checkbox is updated to the parent component
   * @param isFormValid
   */
  check(isFormValid: boolean) {
    this.deviceTypeEvent.emit({isFIPSRestricted: !this.allowNonFips, isValid: isFormValid});
  }

}
