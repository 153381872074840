<h5 class="confirm-dialog-title">
  {{data?.title}}
</h5>
<div>
  {{data?.message}}
</div>
<div class="no-gutters">
  <button class="btn btn-next" name="next" type="button" (click)="confirm()">{{data?.confirmLabel}}</button>
  <button class="btn btn-default" name="cancel" type="button" (click)="cancel()">{{data?.cancelLabel}}</button>
</div>
