import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wizard-footer',
  templateUrl: './wizard-footer.component.html',
  styleUrls: ['./wizard-footer.component.scss']
})
export class WizardFooterComponent implements OnInit {
  @Input() currentStepIdx: number;
  @Input() isCurrentStepValid: boolean;
  @Input() nextLabel: string;
  @Output() cancelEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  @Output() nextEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
