<h5 class="mb-3 error-title">
  <svg class="icon-svg title-icon"><use [attr.xlink:href]="icon" /></svg>
  {{ title }}
</h5>

<p [innerHTML]="cleanedText"></p>

<ng-container *ngIf="traceId">
  <p> {{ contact }} </p>

  <div role="alert" name="incidentId">
    <strong>Incident ID:</strong> {{ traceId }}
  </div>
</ng-container>


<div class="row no-gutters">
  <button class="btn btn-next" name="next" type="button" data-error-ok (click)="close()">Close</button>
</div>
