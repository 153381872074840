import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TenantsModule } from './services/tenants.module';
import { HaroProvider } from './shared/services/haro.service';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './services/InMemoryDbService';
import { environment } from '../environments/environment';
import { PartitionsModule } from './features/partitions/partitions.module';
import { PreLoginModule } from './features/prelogin/prelogin.module';
import { CookieService } from 'ngx-cookie-service';
import { SessionLoggedOutModule } from './session-logged-out/session-logged-out.module';
import { InitializerModule } from './initializer/initializer.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    CoreModule.forRoot(),
    InitializerModule,
    // sub modules
    TenantsModule,
    PartitionsModule,
    PreLoginModule,
    HttpClientModule,
    SessionLoggedOutModule,

    environment.production ? [] : HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
      passThruUnknownUrl: true
    }),
  ],
  providers: [
    HaroProvider,
    [CookieService]
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
