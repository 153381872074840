import { LoginAction } from "../auth/auth.interface";
import { AuthService } from "../auth/auth.service";
import { SessionExpiryService } from "../auth/session-expiry.service";
import { HsmodUiConfig } from "../core/hsmod-ui-config";

export function appInitializer(authService: AuthService, config: HsmodUiConfig, sessionExpiryService: SessionExpiryService) {
  return async () => {
    if (!config.FF_ONE_WELCOME) {
      // Load the application if UAA is active
      return;
    }
    const loginState = await authService.determineLoginState();
    if (sessionExpiryService.check()) {
      // The session has expired that the user has been logged out.
      return;
    }
    switch (loginState.loginAction) {
      case LoginAction.LoggedIn:
        if (loginState.hsmodUiState !== undefined) {
          // hsmodUiState is defined only when the user has just got the token
          // Redirect the user to the restored hsmod-ui state
          authService.redirectToHsmodUiState(loginState.hsmodUiState);
        }
        return;
      case LoginAction.OwRedirect:
        authService.login();
        // Do not load the application
        return Promise.reject();
    }
  }
}
