<ng-container *ngIf="configForm">
  <ng-template [ngIf]="configForm.valid"> <!--edited?-->
    <svg focusable="false" class="icon-svg input-icon input-success">
      <use xlink:href="#check"></use>
    </svg>
  </ng-template>
  <ng-template [ngIf]="!configForm.valid && (configForm.control.dirty)"> <!--edited?-->
    <svg focusable="false" class="icon-svg input-icon input-error">
      <use xlink:href="#warning"></use>
    </svg>
  </ng-template>
</ng-container>
