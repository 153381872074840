<div class="table-container table-holder mb-4 pt-3">
  <div class="search-container">
    <!-- Search bar on top of the table -->
    <input #filter id="searchBox" type="search" placeholder="Search"
           class="filter form-control form-control-sm ml-4"
           (keyup)="onFilter()" [(ngModel)]="filterText"/>
    <div *ngIf="description" data-locator="description" class="d-inline-block mt-4 ml-4">{{description}}</div>
    <div *ngIf="btnLabel" class="mr-2">
      <button class="btn btn-next mr-2" type="button" id="createBtn"
              (click)="onCreate()" data-locator="new-client">{{btnLabel}}
      </button>
    </div>
  </div>

  <!-- caller provides the table here -->
  <ng-content></ng-content>

  <!-- Pagination for all the tables -->
  <mat-paginator
    [length]="dataLength"
    [pageSize]="10"
    (page)="page.emit($event)"
    showFirstLastButtons="true"
    hidePageSize="true">
  </mat-paginator>
</div>
