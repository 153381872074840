import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SessionExpiryService } from './session-expiry.service';

/**
 * Adds session-expiry hooks to Angular HTTP requests and responses.
 * We check for two cases:
 *
 * - An HTTP request is about to be made and the user's token has expired
 * - An HTTP response was received with status 401
 *
 * Both cases send the user to the session-expired state.
 */
@Injectable()
export class SessionExpiryInterceptor implements HttpInterceptor {

  constructor(private SessionExpiryService: SessionExpiryService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.SessionExpiryService.isSessionEnded()) {
      this.SessionExpiryService.logout();
      return observableThrowError(sessionExpiredError());
    }

    this.pause();

    // Restart expired-detection when we get a response
    const resumeOnResponse = (event) => {
      if (event instanceof HttpResponse || HttpErrorResponse) {
        this.resume();
      }
    };

    return next.handle(req).pipe(
      tap(resumeOnResponse, resumeOnResponse),
      catchError(error => {
        if (!(error instanceof HttpErrorResponse) || error.status !== 401) {
          return observableThrowError(error);
        }

        // We got a 401 response. That means the session expired between the time the request
        // was sent and when the server got it.
        console.log(`Session expired, cancelling response from ${error.url}`);
        this.SessionExpiryService.logout();
        return observableThrowError(sessionExpiredError());
      }), );
  }

  pause() {
    this.SessionExpiryService.stopTimer();
  }

  resume() {
    this.SessionExpiryService.startTimer();
  }
}

function sessionExpiredError() {
  return new Error('Session expired');
}
