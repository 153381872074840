<header class="d-flex justify-content-between align-items-center container theader px-0">
  <div class="header-icon-holder">
    <gem-banner-image></gem-banner-image>
  </div>
  <div class="header-label">
    HSM on Demand
  </div>

  <div class="d-flex">

    <div *ngIf="userName" ngbDropdown>
      <button class="user d-flex rounded align-items-center p-0"
              ngbDropdownToggle id="headerDropdownToggle" role="button">
        <div class="avatar">
          <svg class="icon-svg">
            <use xlink:href="#admin-user"></use>
          </svg>
        </div>
        <div class="d-flex flex-column text-left user-info">
          <span class="username">{{userName}}</span>
          <span class="role">{{userRole}}</span>
        </div>
        <div class="dropdown">
          <svg class="icon-svg">
            <use xlink:href="#caret-down"></use>
          </svg>
        </div>
      </button>

      <ul class="dropdown-menu w-120" ngbDropdownMenu role="menu" aria-labelledby="headerDropdownToggle">
        <li class="dropdown-item">
          <a class="inline" href="{{ dpodUrl }}" target="_blank">Manage your account in DPoD
            <img class="external-url" src="images/externalLink.svg" alt="dashboard url" />
          </a>
        </li>
        <li class="dropdown-item" *ngFor="let item of getUserActions()"
            [attr.data-locator]="item.locator" (click)="item.action()">{{item.text}}
        </li>
      </ul>
    </div>
  </div>
</header>
