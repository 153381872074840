import { Component, Inject } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PARTITION_ROUTES } from './features/partitions/partitions.config';
import { HttpService } from './services/HttpService';
import { SessionExpiryService } from './auth/session-expiry.service';
import { HsmodUiConfig } from './core/hsmod-ui-config';
import { ConfigToken } from './config/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  processing: boolean;
  isAccessForbidden = false;
  isSessionEnded: boolean;
  constructor(public authService: AuthService, public location: Location, private cookieService: CookieService,
              private httpService: HttpService,
              private route: ActivatedRoute, private router: Router, private sessionExpiryService: SessionExpiryService,
              @Inject(ConfigToken) private config: HsmodUiConfig) {
    this.init();
  }

  init() {
    this.adjustRoutes();
    this.sessionExpiryService.getSessionExp().subscribe(res => {
      this.isSessionEnded = res;
    });
    this.getServiceId();
    this.httpService.getAccessForbidden().subscribe(res => {
      this.isAccessForbidden = res;
    });
  }

  getServiceId() {
    if (this.config.FF_ONE_WELCOME) {
      return;
    }
    let serviceId;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        serviceId = this.route.snapshot.queryParams.dashboard_id || this.getServiceIdFromCookie();
        if (serviceId) {
          this.processMetadataAndLogin(serviceId);
        } else {
          this.redirectToUAAOrPrelogin();
        }
      }
    });
  }

  redirectToUAAOrPrelogin() {
    if (this.isSessionEnded) {
      return;
    }

    if (this.authService.getToken() === undefined || this.authService.getToken() === null) {
      this.checkTenantIdInCookie();
    } else {
      if (!this.location.path()) {
        this.router.navigate([`${PARTITION_ROUTES.basePath}`]);
      }
    }
  }

  checkTenantIdInCookie() {
    const tenantMetadata = this.cookieService.get('tenant.metadata');
    if (tenantMetadata) {
      const url = `${JSON.parse(tenantMetadata)?.url}`;
      if (url) {
        this.authService.login(url);
        return;
      }
    }
    this.goToPreLogin();
  }

  getServiceIdFromCookie(){
    if (this.authService.getToken() === undefined || this.authService.getToken() === null) {
      const metadata = this.cookieService.get('tenant.metadata');
      return metadata ? JSON.parse(metadata).service : null;
    }
  }

  processMetadataAndLogin(serviceId: string) {
    this.processing = true;
    this.authService.getTenantMetadata(serviceId).subscribe(res => {
      const loginUrl = res.body?.login_url;
      this.cookieService.set('tenant.metadata', JSON.stringify({ service: serviceId, url: loginUrl }));
      this.authService.login(loginUrl);
    }, err => {
      this.processing = false;
      this.goToPreLogin();
    });
  }

  goToPreLogin() {
    this.router.navigate(['prelogin']);
  }

  showLayout() {
    return this.authService.isRedirecting() || this.processing;
  }

  private adjustRoutes() {
    if (this.config.FF_ONE_WELCOME) {
      // The prelogin route shouldn't be available with OW
      this.router.config = this.router.config.filter(route => route.path !== 'prelogin');
    }
  }
}
