import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ConfigToken } from '../config/config';
import { appInitializer } from './initializer.factory';
import { SessionExpiryService } from '../auth/session-expiry.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitializer,
      deps: [AuthService, ConfigToken, SessionExpiryService],
    },
  ],
})
export class InitializerModule {}
