<hsmod-table (filter)="onFilter($event)" [dataLength]="dataSource.data.length">
  <table #table mat-table matSort [dataSource]="dataSource">
    <caption class="d-none">List of services</caption>

    <!-- Partition Name column -->
    <ng-container matColumnDef="name">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let partition" class="wide-column">
        <a class="gem-link" (click)="goToPartitionDetails(partition.serialNumber)" externalLink>{{partition.name}}</a>
      </td>
    </ng-container>

    <!-- Serial Number column -->
    <ng-container matColumnDef="serialNumber">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Partition Serial No.</th>
      <td mat-cell *matCellDef="let partition">{{ partition.serialNumber }}</td>
    </ng-container>

    <!-- FIPS Restricted column -->
    <ng-container matColumnDef="deviceType">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{fipsRestrictions}}</th>
      <td mat-cell *matCellDef="let partition">{{partition.deviceType | fips}}</td>
    </ng-container>

    <!-- Created At column -->
    <ng-container matColumnDef="createdAt">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let partition" class="created-at-column">
        {{ partition.createdAt | formatDate }}
      </td>
    </ng-container>

    <!-- Created By column -->
    <ng-container matColumnDef="createdBy">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
      <td mat-cell *matCellDef="let partition" class="created-by-column">
        {{ partition.createdBy || 'Unavailable' }}
      </td>
    </ng-container>

    <!-- Actions column -->
    <ng-container matColumnDef="actions">
      <th scope="col" mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let partition" class="actions-column">
        <div class="action-cell" [attr.data-group-name]="partition.name">
          <div class="icon-inside-icon">
              <span class="grid-action" (click)="openConfirmDelete(partition)" data-locator="delete-client">
                  <svg class="icon-svg outer-icon">
                      <use xlink:href="#action-box" />
                  </svg>
                  <svg class="icon-svg inner-icon">
                      <use xlink:href="#trash" />
                  </svg>
              </span>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Rows definition -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let partition; columns: displayedColumns;">
    </tr>
  </table>
</hsmod-table>

