/** Decoded JWT token in the implicit flow */
export interface JWT {
  email: string;
  exp: number;
  iss: string;
  scope: string[];
  user_id: string;
}

/** Subset of the claims in the user's access token that Hodor provides to us. */
export interface HodorJwtClaims {
  email: string;
  iss: string;
  scope: string[];
  user_id: string;
}

/**
 * Interface implemented by auth services
 *
 * TODO: after Hodor is enabled, remove this and just use AuthServiceHodor
 */
export interface IAuthService {
  /** @deprecated */
  getToken(): string;

  getTenantId(): string;

  getSubscriberGroupId(): string;

  hasScope(s: string): boolean;

  isEmailVerified(): boolean;

  isRedirecting(): boolean;

  isStateValid(s: string): boolean;

  login(returnTo?: string): void;

  logout(): void;

  /** @deprecated */
  reset(): void;
}

export enum LoginAction {
  LoggedIn = 'LoggedIn',
  MultiChoice = 'MultiChoice', // Support in hsmod-ui later (multi-tenant case)
  OwRedirect = 'OwRedirect',
  HsmodRedirect = 'HsmodRedirect' // Support in hsmod-ui later (multi-tenant case)
}

export interface LoginState {
  loginAction: LoginAction;
  hsmodUiState?: string; // hsmod-ui state restored after back-redirect from OW
  redirectionUrl?: string; // Support in hsmod-ui later (multi-tenant case)
}
