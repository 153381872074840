import { DOCUMENT } from '@angular/common';
import { InjectionToken, Provider } from '@angular/core';

export const ConfigToken = new InjectionToken<Config>('config');

export const ConfigProvider: Provider = {
  provide: ConfigToken,
  deps: [DOCUMENT],
  useFactory: configurationFactory,
};

/**
 * Reads configuration properties out of the DOM and returns a parsed Config object.
 */
export function configurationFactory(doc: Document): Config {
  const configElement = doc.querySelector(`script[type=config]`);
  if (!configElement) {
    return {};
  }
  return JSON.parse(configElement.textContent || '{}');
}

/**
 * Config is a dictionary of key-value pairs obtained from the app environment vars.
 */
export interface Config {
  [key: string]: any;
}
