import { Inject, Injectable } from '@angular/core';
import * as jwtDecodeLib from 'jwt-decode';
import { unverifiedScopeSuffix } from './auth.constants';
import { IAuthService, JWT } from './auth.interface';
import { getSubscriberGroupId, getTenantId, isEmailVerified } from './auth.utils';
import { JwtDecode } from './jwt-decode';
import { OAuth2ClientJS } from './oauth2-client-js';
import * as AuthScopes from './roles.constants';
import ValidStates from './valid-states.constants';
import { HttpService } from '../services/HttpService';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { ConfigToken } from '../config/config';
import { HsmodUiConfig } from '../core/hsmod-ui-config';

/**
 * Creates an AuthService that works with UAA.
 *
 * NOTE: This is similar to the one from dpod-ui except that it's Angular 2+.  Functionality wise it should be the same
 *
 * After the constructor returns, the AuthService is in one of two states:
 *
 * - The user is logged in. `getToken()` and `getIdentity()` return nonnull.
 * - The user is not logged in. `getToken()` and `getIdentity()` return null.
 */
@Injectable()
export class AuthUAAService implements IAuthService {

  redirecting = false;
  constructor(
    private window: Window,
    @Inject(OAuth2ClientJS) private oauth2: any,
    @Inject(JwtDecode) private jwtDecode: JWTDecodeLib,
    @Inject(ConfigToken) public config: HsmodUiConfig,
    private httpService: HttpService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    this.init();
  }
  private identity = null;
  private provider = null;
  private scopes: readonly string[] = [];

  // Helper function called only from the constructor
  init() {
    if (this.provider) {
      throw new Error('init() was already invoked');
    }

    this.provider = new this.oauth2.Provider({
      id: this.config.AUTH_CLIENT_ID,
      authorization_url: '/oauth/authorize',
    });

    // First check if we are returning from a login redirect. Note, need to parse the
    // hash with Angular routing params stripped out (otherwise provider gets confused).
    const hash = location.hash;
    let fromRedirect = false;
    if (hash) {
      try {
        const response = this.provider.parse(hash);
        const prev = response.metadata;
        fromRedirect = true;
        location.hash = '';
      } catch (e) {
        // Ignore
        console.error(e);
      }
    }

    // At this point we might have a token (either from the login redirect or from localStorage)
    const token = this.provider.getAccessToken();
    if (token) {
      console.log(`Got token ${fromRedirect ? 'from oauth redirect' : 'from cache'}`);
      this.identity = this.jwtDecode.jwtDecode(token);
      this.scopes = this.identity.scope;
    } else {
      console.log('Not logged in');
    }
  }

  /**
   * Determine whether the user is allowed to view the given UI-router state. In other words,
   * check whether the user has any scope for which the state is valid.
   */
  isStateValid(state): boolean {
    return Object.keys(AuthScopes).some((scopeName) => {
      const scope = AuthScopes[scopeName];
      return this.hasScope(scope) && (ValidStates[scopeName] || []).includes(state);
    });
  }

  /**
   * @returns Whether the user has verified their email address
   */
  isEmailVerified(): boolean {
    return isEmailVerified(this.scopes);
  }

  /**
   * @returns Whether we've started redirecting the user to the login or logout page.
   */
  isRedirecting(): boolean {
    return this.redirecting;
  }

  /**
   * @returns The encoded JWT (also called access_token)
   */
  getToken(): string {
    return this.provider.getAccessToken();
  }

  /**
   * @returns The decoded JWT
   */
  getIdentity(): JWT {
    return this.identity;
  }

  /**
   * @returns The id of the tenant that the user is logged into.
   */
  getTenantId(): string {
    return getTenantId(this.scopes);
  }

  /**
   * @returns The id of the subscriber group that the user belongs to.
   * null is returned if the user doesn't belong to a subscriber group (e.g. if the user is an SP Admin or Operator)
   */
  getSubscriberGroupId(): string {
    return getSubscriberGroupId(this.scopes);
  }

  /**
   * todo verify that nothing is using this incorrectly as we also include unverified scopes
   * @returns `true` if the user has the given scope or an "unverified" variant thereof.
   */
  hasScope(scope: string): boolean {
    return this.scopes.includes(scope) || this.scopes.includes(scope + unverifiedScopeSuffix);
  }

  /**
   * Checks if one of the scopes exist for this user
   * use this when possible instead of `hasScope`
   * which also checks for `unverified` which may lead to something unintended
   * @param scopes  array of passed in scopes
   */
  hasAnyScope(...scopes: string[]): boolean {
    return !!this.scopes.find((userScope) => scopes.includes(userScope));
  }

  /**
   * @param returnTo The $location path to restore after login (default: current path)
   */
  login(tenantUrl: string, returnTo?: string) {
    // override the provider url with tenantUrl passed
    this.provider = new this.oauth2.Provider({
      id: this.config.AUTH_CLIENT_ID,
      authorization_url: `${tenantUrl}/oauth/authorize`,
    });
    const window = this.window;
    const request = new this.oauth2.Request({
      client_id: this.config.AUTH_CLIENT_ID,
      redirect_uri: this.getRedirectLocation(),
      metadata: returnTo || location.pathname,
    });

    const uri = this.provider.requestToken(request);

    // Later we need to check if the response was expected so save the request
    this.provider.remember(request);

    console.warn(`redirecting to login page:${uri}`);
    this.redirecting = true;
    window.location.href = uri;
  }

  logout() {
    const window = this.window;
    this.reset();
    const redirectUri =  window.location.origin;
    // Log user out of UAA then redirect back to this page. After the redirect the user should
    // end up in the login flow again, allowing them to re-login to DPOD if they wish.
    const tenantMetadata = this.cookieService.get('tenant.metadata');
    const uri = `${JSON.parse(tenantMetadata).url}/logout.do?redirect=${encodeURIComponent(redirectUri)}`;
    console.warn(`redirecting to logout page: ${uri}`);
    this.redirecting = true;
    window.location.href = uri;
  }

  /**
   * Returns the AuthService to the "not logged in" state.
   */
  reset() {
    this.provider.deleteTokens();
    this.identity = null;
    this.scopes = [];
  }
  getTenantMetadata(serviceId: string): Observable<any> {
    const apiUrl = 'info';
    return this.httpService.get(`${apiUrl}`, `dashboard_id=${serviceId}`);
  }

  validateUrl(uaaUrl: string): Observable<any> {
    return this.http.get(uaaUrl);
  }

  /**
   * @returns The URI where UAA should redirect back to after the user logs in.
   */
  private getRedirectLocation(): string {
    const path = window.location.pathname;
    // discard fragment to avoid endless loop
    return window.location.origin + (path === '/' ? '' : path);
  }
}

// Can't seem to directly reference this as a type from jwt-decode library
interface JWTDecodeLib {
  jwtDecode: (token: string, options?: jwtDecodeLib.JwtDecodeOptions) => JWT;
}
