<div *ngIf="!showLayout()" class="app">
  <div *ngIf="!isSessionEnded" class="head-nav-holder">
    <gem-header></gem-header>
  </div>

  <!-- Application Routing here-->
  <div class="main main-viewport-holder px-0 logged-in">
    <!-- Include this 'page' class here; that means it is not required in all the components -->
    <div class="page">
      <div *ngIf="!isSessionEnded && !isAccessForbidden">
        <router-outlet></router-outlet>
      </div>
      <div *ngIf="isSessionEnded">
        <session-logged-out></session-logged-out>
      </div>
      <div *ngIf="isAccessForbidden && !isSessionEnded">
        <app-error-page></app-error-page>
      </div>
    </div>
  </div>

  <gem-footer *ngIf="!isSessionEnded" class="gem-footer"></gem-footer>

  <gem-help-drawer *ngIf="!isSessionEnded"></gem-help-drawer>
  <!-- Proceeds to load the custom icons which the entire app can use -->
  <gem-icons></gem-icons>
</div>


