<ng-container *ngIf="!isProcessing">
  <div class="gem-header section-header">
  <div>HSM Partitions</div>
  <div *ngIf="hasPartitions()">
    <button name="create-partition" class="btn btn-next btn-text" (click)="openCreatePartitionDialog()">
      Create Partition
    </button>
  </div>
  </div>
</ng-container>

<!-- do not render this block until API call is done -->
<ng-container *ngIf="!isProcessing">
  <!-- display the Create Partition UI template if there are no partitions -->
  <ng-container *ngIf="!hasPartitions(); else partitionList">
    <empty-partition (createEvent)="openCreatePartitionDialog()"></empty-partition>
  </ng-container>
  <!-- partition list table -->
  <ng-template #partitionList>
    <partition-list [partitionData]="partitions" (loadPartitions)="getPartitions($event)"></partition-list>
  </ng-template>
</ng-container>

