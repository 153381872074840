import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { PreLoginComponent } from './components/prelogin.component';

// Remove the prelogin page once UAA is gotten rid of
@NgModule({
  declarations: [
    PreLoginComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
  ],
  exports: [
    PreLoginComponent
  ]
})
export class PreLoginModule {
}
