import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.scss']
})
export class WizardStepsComponent implements OnInit {

  @Input() steps: string[] = [];
  @Input() currentStepIdx = 0;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * This method returns the CSS style class needed to display in the UI for the svg images
   * @param stepIndex
   */
  getStepClasses(stepIndex) {
    return {
      complete: stepIndex < this.currentStepIdx,
      active: stepIndex === this.currentStepIdx,
      todo: stepIndex > this.currentStepIdx,
    };
  }
}
