<div>
  <div class="empty-state-container">
    <div class="empty-state-header">
      Create a partition
    </div>
    <div class="empty-state-text">
      Create a new partition to get started. Once a partition is created you can create a client for that partition that
      will allow you to connect your application.
      <a class="empty-state-help" href="{{ partitionHelp.url | docLink }}" target="_blank" externalLink>Need help?</a>
    </div>
    <div>
      <img class="empty-state-img" src="images/clientEmptyState.svg" alt="envelope icon" role="presentation">
    </div>
    <div class="empty-state-btn-area">
      <button class="btn btn-next empty-state-btn" type="button" (click)="openDialog()">Create Partition</button>
    </div>
  </div>
</div>
