import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { AuthService } from './auth.service';
import { WindowToken } from '../shared/services/window';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

const POLL_INTERVAL = 1000 * 5; // 5 seconds

/**
 * Polls to check whether the expiry time given in the user's JWT has passed.
 * If it has, resets the `AuthService` and sends the user to the "session expired" state.
 *
 * TODO this service is only useful when using the implicit auth flow, it should be
 * deleted after we move to Hodor (session cookie)
 */
@Injectable()
export class SessionExpiryService {

  constructor(
    private location: Location,
    // TODO Angular location service
    @Inject(DOCUMENT) private document: Document,
    @Inject(WindowToken) private window: Window,
    private authService: AuthService,
    private zone: NgZone,
    private injector: Injector,
    private router: Router
  ) {
    this.setExpiryTime();
    this.startTimer();
  }
  private expiryTime: number = null;
  private intervalId: number;
  private sessionExp = new BehaviorSubject(false);

  startTimer() {
    if (this.intervalId) {
      return;
    }

    // Long running intervals can block Protractor from starting, so we schedule this
    // interval outside the Angular zone
    // http://www.syntaxsuccess.com/viewarticle/ngupgrade-and-long-running-intervals
    this.zone.runOutsideAngular(() => {
      this.intervalId = this.window.setInterval(this.check.bind(this), POLL_INTERVAL);
    });
  }

  stopTimer() {
    this.window.clearInterval(this.intervalId);
    this.intervalId = null;
  }

  check() {
    if (!this.isSessionEnded()) {
      return false;
    }

    this.logout();
    return true;
  }

  /**
   * Called when we detect the implicit JWT has expired, or when we get a 401 response
   * on an Ajax call.
   */
  logout() {
    this.stopTimer();

    // The action we take depends on whether we're using session (hodor) or implicit
    this.authService.reset();

    this.zone.run(() => {
      this.setSessionExp(true);
      this.router.navigate([''], {skipLocationChange: true});
    });
  }

  isSessionEnded() {
    // In case OW is enabled the user token may be retrieved after the session expiry service is created
    if (!this.expiryTime) {
      this.setExpiryTime();
    }
    return typeof this.expiryTime === 'number' && Date.now() > this.expiryTime;
  }

  public getSessionExp() {
    return this.sessionExp;
  }

  public setSessionExp(isExp){
    this.sessionExp.next(isExp);
  }

  private setExpiryTime() {
    const identity = this.authService.getIdentity();
    if (identity) {
      this.expiryTime = identity.exp * 1000; // convert to milliseconds so it's comparable to Date.now()
    }
  }
}
