import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [],
  exports: [
    FooterComponent
  ]
})
export class FooterModule {
}
