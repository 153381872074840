import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-partition-dialog',
  templateUrl: './delete-partition-dialog.component.html',
  styleUrls: ['./delete-partition-dialog.component.scss']
})
export class DeletePartitionDialogComponent implements OnInit {
  partitionName = '';
  enteredName = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DeletePartitionDialogComponent>) { }

  ngOnInit(): void {
    this.partitionName = this.data.partitionName || '';
  }

  // sends whether the user clicked on delete or cancel button
  btnAction(action: string) {
    this.dialogRef.close(action);
  }

  isValidName() {
    return this.enteredName === this.partitionName;
  }
}
