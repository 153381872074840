import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastrService: ToastrService) { }

  success<T>(msg: string): ActiveToast<T> {
    // refer shared.module.ts for common toast options applied
    return this.toastrService.success(msg, '');
  }
}
