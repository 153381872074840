import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { flyInOut } from './toast.animations';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [flyInOut()],
})
export class ToastComponent extends Toast {

  constructor(protected toastrService: ToastrService,
              public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  close() {
    this.toastPackage.triggerAction('closed');
    this.remove();
  }
}
