<form #configForm="ngForm">
  <div class="details-panel">
    <div class="details-panel-section">
      <div>
        <label for="partitionName" class="control-label">Partition Name</label>
        <!-- use this common class "input-textbox-container" as a wrapper for textbox with validation icons -->
        <div class="input-textbox-container">
          <!-- when using "maxlength" in template driven forms, Angular does not let you show error message
          when it reaches max length. The default behavior is it stops the user from typing.
          Refer configure-partition.component.ts file for the hack logic. The hack uses both custom logic as well as
           [maxLength] behavior to show the error message as well as prevent user typing -->
          <input id="partitionName" #partitionNameField="ngModel" name="partitionName" class="form-control"
                 minlength="4"
                 [maxlength]="nameMaxLength"
                 [(ngModel)]="partitionName" required
                 (input)="nameChange(configForm.valid)"
                 (keydown)="onKeyDown()"
                 (keyup)="onKeyUp()"
                 [pattern]="partitionNamePattern"
                 trim="blur"
                 autofocus>
          <!-- use this "validation-icon component to display the tick and warning icons inside the text box -->
          <validation-icon [configForm]="configForm"></validation-icon>
        </div>
        <div *ngIf="partitionNameField.dirty || maxCharReached" id="error-message-partitionName" class="text-danger error-message"
             role="alert">
          <div *ngIf="partitionNameField.errors?.required">Please enter a partition name</div>
          <div *ngIf="partitionNameField.errors?.minlength">Minimum 4 characters</div>
          <div *ngIf="partitionNameField.errors?.pattern">Invalid partition name. Only alphanumeric characters, "@" and underscores are allowed.</div>
          <div *ngIf="maxCharReached">Maximum {{nameMaxLength}} characters</div>
        </div>
      </div>
    </div>
    <div class="details-panel-section">
      <div class="fips-restrictions">
        <input id="fipsRestriction" type="checkbox" class="modal-checkbox" [(ngModel)]="allowNonFips" name="allow-fips"
               (change)="check(configForm.valid)">
        <span>Remove FIPS restrictions</span>
      </div>
    </div>
  </div>
</form>

<div class="alert-container" *ngIf="allowNonFips">
  <div class="alert-img-container">
    <span>
        <svg class="icon-svg align-sub" role="button">
            <use xlink:href="#bang-triangle"></use>
        </svg>
    </span>
  </div>
  <div class="alert-msg">
      This partition will be hosted on an HSM that supports non-FIPS algorithms.
      Please ensure that FIPS compliance is not a requirement for your partition.
      Once the partition is created, this selection cannot be changed.
  </div>
</div>
