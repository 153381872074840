import {
  InMemoryDbService,
  RequestInfo,
  ResponseOptions
} from 'angular-in-memory-web-api';
import { Injectable } from '@angular/core';

// Pseudo GUID generator
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

@Injectable()
export class InMemoryDataService implements InMemoryDbService {

  map = new Map<string, any>();

  createDb(requestInfo: RequestInfo) {
    const bundle = [];
    const info = [
      {
        dashboard_id: '123', login_url: 'https://dpod-dev-ci.uaa.system.chimera.dpod.live'
      },
      {
        dashboard_id: '789', login_url: 'https://rashmi2.uaa.system.chimera.dpod.live'
      }
    ];

    /* The 'id' field is only for the mock data. The actual response doesn't have this field.
       Having this 'id' field helps in deleting the row from the mock data without overriding/intercepting.
       Angular InMemory Web Api looks for the 'id' field when deleting.
    */
    const partitions = [
      {
        id: '1272472323', name: 'Partition 1', serialNumber: '1272472323', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-06-12T15:43:55.445Z', createdBy: 'abc@abc.com',
        clients: [
          {
            id: '1524312573', name: 'Client 1', clientId: '1524312573', createdAt: '2021-08-28', createdBy: 'testuser1'
          },
          {
            id: '4617531252', name: 'Client 2', clientId: '4617531252', createdAt: '2021-08-30', createdBy: 'testuser2'
          }
        ]
      },
      {
        id: '3423342323', name: 'Partition 2', serialNumber: '3423342323', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-10-04T12:41:15.335Z', createdBy: 'xyz@abc.com'
      },
      {
        id: '4656576323', name: 'Partition 3', serialNumber: '4656576323', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-10-26T18:22:47.332Z', createdBy: 'def@abc.com'
      },
      {
        id: '2738299193', name: 'Partition 4', serialNumber: '2738299193', deviceType: 'cryptovisor', metadata: {},
        createdAt: '2021-11-05T21:07:17.289Z', createdBy: 'owie@abc.com'
      },
      {
        id: '7781839282', name: 'Partition 5', serialNumber: '7781839282', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-05-19T12:28:18.382Z', createdBy: 'lsls@abc.com'
      },
      {
        id: '2711939200', name: 'Partition 6', serialNumber: '2711939200', deviceType: 'cryptovisor', metadata: {},
        createdAt: '2021-03-02T05:07:09.005Z', createdBy: 'cqi@abc.com'
      },
      {
        id: '5738839593', name: 'Partition 7', serialNumber: '5738839593', deviceType: 'cryptovisor', metadata: {},
        createdAt: '2021-09-T14:51:17.325Z', createdBy: 'pwi@abc.com'
      },
      {
        id: '9288293827', name: 'Partition 8', serialNumber: '9288293827', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-07-11T08:19:23.381Z', createdBy: 'rrq@abc.com'
      },
      {
        id: '4656576323', name: 'Partition 9', serialNumber: '4656576323', deviceType: 'cryptovisor', metadata: {},
        createdAt: '2021-07-22T17:21:35.235Z', createdBy: 'you@abc.com'
      },
      {
        id: '4829200489', name: 'Partition 10', serialNumber: '4829200489', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-12-13T02:14:14.145Z', createdBy: 'kwe@abc.com'
      },
      {
        id: '8839294945', name: 'Partition 11', serialNumber: '8839294945', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-08-28T09:44:12.921Z', createdBy: 'art@abc.com'
      },
      {
        id: '4526737384', name: 'Partition 12', serialNumber: '4526737384', deviceType: 'cryptovisor', metadata: {},
        createdAt: '2021-08-21T00:00:05.211Z', createdBy: 'qpeo@abc.com'
      },
      {
        id: '1093928382', name: 'Partition 13', serialNumber: '1093928382', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-08-02T19:13:25.415Z', createdBy: 'slwo@abc.com'
      },
      {
        id: '2182218283', name: 'Partition 14', serialNumber: '2182218283', deviceType: 'cryptovisor', metadata: {},
        createdAt: '2021-05-04T02:55:31.215Z', createdBy: 'siw@abc.com'
      },
      {
        id: '7588349399', name: 'Partition 15', serialNumber: '7588349399', deviceType: 'cryptovisor_fips', metadata: {},
        createdAt: '2021-02-01T11:273:15.445Z', createdBy: 'mnm@abc.com'
      }
    ];

    const clients = [
      {
        id: '1524312573', name: 'Client 1', clientId: '1524312573', createdAt: '2021-08-28', createdBy: 'testuser1'
      },
      {
        id: '4617531252', name: 'Client 2', clientId: '4617531252', createdAt: '2021-08-30', createdBy: 'testuser2'
      }
    ];

    return {bundle, clients, partitions, info};
  }

  post(requestInfo: RequestInfo) {
    // Uncomment below line to mock error during partition create and call 'getErrorResponse` method in the responseInterceptor
    // requestInfo.collectionName = 'partition';
    const req: any = requestInfo.req;
    const data = req.body;
    const collectionName = requestInfo.collectionName;

    if (collectionName === 'clients') {
        const serialNumber = data.partitionSerialNumber;
        const clientId = guid();
        const client = {
          id: clientId, name: data.name, clientId, createdAt: '2021-01-17T15:43:55.445Z', createdBy: 'testuser1'
        };
        if (this.map.get(serialNumber)) {
          this.map.get(serialNumber).set(clientId, client);
        } else {
          const mp = new Map<string, any>();
          mp.set(clientId, client);
          this.map.set(serialNumber, mp);
        }
    }
  }

  delete(requestInfo: RequestInfo) {
    const collectionName = requestInfo.collectionName;

    // Uncomment below line to mock error during partition delete and call 'getErrorResponse` method in the responseInterceptor
    // if (collectionName === 'partitions'){
    //   requestInfo.id = '';
    // }

    if (collectionName === 'clients') {
      this.map.forEach((value: any, key: string) => {
        value.delete(requestInfo.id);
      });
    }
  }

  /**
   * Intercepts ResponseOptions from default HTTP method handlers; reports interception to console.log.
   *
   * @param responseOptions The data constituting the HTTP response.
   * @param requestInfo The data constituting the HTTP request.
   */
  responseInterceptor(responseOptions: ResponseOptions, requestInfo: RequestInfo): ResponseOptions {
    const collectionName = requestInfo.collectionName;
    const method = requestInfo.method;

    if (collectionName === 'bundle') {
      responseOptions.body = new Blob([''], {type : 'blob'});
      responseOptions.status = 200;
    }

    if (collectionName === 'partitions') {
      if (method === 'post') {
        responseOptions = this.postPartitions(responseOptions, requestInfo).responseOptions;
      } else if (method === 'delete') {
        responseOptions.status = 204;
      } else if (method === 'get') {
        if (requestInfo.req.url.endsWith('clients')) {
          const mp = this.map.get(requestInfo.id);
          const clientList = [];
          if (mp) {
            mp.forEach((value: any, key: string) => {
              clientList.push(value);
            });
          }

          responseOptions.body = clientList;
        }
      }
    } else if (collectionName === 'clients') {
      if (method === 'post') {
        responseOptions.status = 201;
      }
    } else if (collectionName === 'info') {
      responseOptions.body = responseOptions.body[0];
      if (!responseOptions.body) {
        responseOptions = {};
        responseOptions.status = 404;
      }
    }

    console.log(`responseInterceptor: ${method.toUpperCase()} ${requestInfo.req.url}: \n${JSON.stringify(responseOptions)}`);
    return responseOptions;
  }

  /**
   * Auxiliary to responseInterceptor() for handling HTTP POST requests for partitions;
   * injects missing fields into the request body and propagates them to the response body, as required by the db.
   *
   * @param responseOptions The data constituting the HTTP POST response.
   * @param requestInfo The data constituting the HTTP POST request.
   */
  postPartitions(responseOptions: ResponseOptions, requestInfo: RequestInfo): {responseOptions: ResponseOptions, requestInfo: RequestInfo} {

    // Inject fields that would be set in the back end
    const body = 'utils' in requestInfo ? requestInfo.utils.getJsonBody(requestInfo.req) : {name: '', deviceType: ''};
    body.id = guid();
    body.serialNumber = body.id;
    body.metadata = {};
    body.createdAt = '';
    body.createdBy = 'abc@abc.com';
    requestInfo.collection.push(body);

    // Augment the response
    responseOptions.body = body;
    responseOptions.status = 201;

    console.log(`requestInterceptor: ${requestInfo.method.toUpperCase()} ${requestInfo.req.url}: \n${JSON.stringify(requestInfo)}`);
    return {responseOptions, requestInfo};
  }

  getErrorResponse(responseOptions, status) {
    responseOptions.status = status;
    responseOptions.error = {
      timestamp: '2021-11-17T16:00:21.284+0000',
      error: 'Unprocessable Entity',
      message: 'You have reached your client capacity for partition 1269045675123. To create additional clients for the partition please upgrade your service plan.',
      path: '/v1/clients',
      traceid: 'e0cbfaa93f536015',
      status
    };
    return responseOptions;
  }
}
