import { Component, OnInit } from '@angular/core';
import { PartitionService } from '../../services/partition.service';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PARTITION_ROUTES, PROGRESS_DIALOG_MESSAGES, TOAST_MESSAGES, UI_TEXT } from '../../partitions.config';
import { DialogService } from '../../../../shared/services/dialog.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'partition-details',
  templateUrl: './partition-details.component.html',
  styleUrls: ['./partition-details.component.scss']
})
export class PartitionDetailsComponent implements OnInit {

  partitionDetails;
  serialNumber;

  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'serialNumber', 'deviceType', 'createdAt', 'createdBy'];
  readonly fipsRestrictions = UI_TEXT.FIPS_RESTRICTIONS;

  constructor(public partitionService: PartitionService, public dialog: DialogService, private route: ActivatedRoute,
              private toastService: ToastService,
              private router: Router) { }

  ngOnInit(): void {
    this.serialNumber = this.route.snapshot.params.serial_number;
    const progressDialogRef  = this.dialog.progress({text: PROGRESS_DIALOG_MESSAGES.LOADING});
    this.partitionService.getPartitionDetails(this.serialNumber).subscribe(partition => {
      if (partition && partition.status === 200) {
        this.partitionDetails = partition.body;
        this.dataSource.data = this.partitionDetails || [];
        progressDialogRef.close();
      } else {
        progressDialogRef.close();
        this.dialog.error(partition);
      }
    }, err => {
      progressDialogRef.close();
      this.dialog.error(err);
    });
  }

  goToPartitions(){
    const partitionsRoute = `${PARTITION_ROUTES.basePath}`;
    this.router.navigate([partitionsRoute]);
  }

  openConfirmDelete() {
    this.partitionService.openConfirmDeletePartition(this.partitionDetails).subscribe(response => {
      // since the service method is used in multiple components, the catchError is handled in partition service
      // when there is a 500 error, the partition service returns an empty response, so the below null check is needed
      if (response) {
        const { res, progressDialogRef } = response;
        if (res && res.status === 204){
          this.toastService.success(TOAST_MESSAGES.PARTITION_DELETED);
          progressDialogRef.close();
          this.router.navigate([`${PARTITION_ROUTES.basePath}`]);
        } else {
          progressDialogRef.close();
          this.dialog.error(res?.body);
        }
      }
    });
  }
}
