import { NgModule } from '@angular/core';
import { BannerImageComponent } from './banner-image.component';

@NgModule({
  declarations: [
    BannerImageComponent,
  ],
  exports: [
    BannerImageComponent
  ]
})
export class BannerImageModule {
}
