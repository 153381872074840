import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmOptions, ProgressOptions } from '../model/dialog-options';
import { DeletePartitionDialogComponent } from '../../features/partitions/components/delete-partition-dialog/delete-partition-dialog.component';
import { ProgressComponent } from '../../components/dialogs/progress/progress.component';
import { ErrorDialogComponent } from '../../components/dialogs/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})

export class DialogService {

  constructor(private matDialog: MatDialog) { }

  /**
   * This function helps to open an Angular dialog based on the input component
   * @param component
   * @param width optional
   * @param data optional
   */
  open(component, width?: string, data?: any) {
    return this.matDialog.open(component, {
      width: width ? width : '800px',
      disableClose: true,
      data
    });
  }

  // this function is called by all components that need to display a confirm dialog box
  confirm(options: ConfirmOptions) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: options,
      width: '430px',
      disableClose: true
    });
    // return the user action(confirm or cancel) to the component that called this method
    return dialogRef.afterClosed();
  }

  // this method is called whenever user tries to delete a partition
  // options should contain the Partition name for display and validation purpose
  openDeletePartition(options) {
    const dialogRef = this.matDialog.open(DeletePartitionDialogComponent, {
      data: options,
      width: '430px',
      disableClose: true
    });
    // return the user action(confirm or cancel) to the component that called this method
    return dialogRef.afterClosed();
  }

  /**
   * This method is used to show any Loading, Preparing, Deleting etc messages during API calls
   * The caller should close the dialog once the API response is received.
   * It is important to return the reference of the dialog object
   *
   * @param : options
   */
  progress(options: ProgressOptions) {
    return this.matDialog.open(ProgressComponent, {
      data: options,
      width: '400px',
      disableClose: true
    });
  }

  /**
   *
   * @param options
   * This method opens the error dialog whenever there is an error from the API
   */
  error(error?) {
    if (error && error.status === 403) {
      return;
    }
    return this.matDialog.open(ErrorDialogComponent, {
      data: error.error || {},
      width: '430px',
      disableClose: true
    });
  }
}
