import { NgModule } from '@angular/core';
import { GemIconsComponent } from './gem-icons.component';

@NgModule({
  declarations: [
    GemIconsComponent,
  ],
  imports: [],
  exports: [
    GemIconsComponent
  ]
})
export class GemIconsModule {
}
