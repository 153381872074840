import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface Error {
  error?: string;
  message?: string;
  traceid?: string;
  status?: number;
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  cleanedText = 'Sorry, an error occurred.';
  traceId: string;
  title = 'An Error Occurred';
  contact = 'If you wish to contact your service provider, please note the following incident ID.';
  icon = '#bang-triangle';

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<ErrorDialogComponent>) { }

  ngOnInit(): void {
    this.setError(this.data);
  }

  setError(errInput: Error) {
    const cleanedError = errInput;

    this.traceId = cleanedError.traceid;

    // Heuristic that tries to split sentences into paragraphs
    if (cleanedError.message) {
      const result = cleanedError.message.match(/([\S\s]+?)([.!?]\s|[.!?]$|$)/g);
      if (result) {
        cleanedError.message = Array.from(result, arrVal => `<p>${arrVal}</p>`).join('');
      }
    }
    // If the status is 500, always show the default error message set at the top.
    // Otherwise set the error message from the incoming object
    if (cleanedError.status && cleanedError.status !== 500){
      this.cleanedText = cleanedError.message || this.cleanedText;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
