import { Component, OnInit } from '@angular/core';
import { PartitionService } from '../services/partition.service';
import { CreatePartitionDialogComponent } from './create-partition-dialog/create-partition-dialog.component';
import { DialogService } from '../../../shared/services/dialog.service';
import { PROGRESS_DIALOG_MESSAGES } from '../partitions.config';

/**
 * This component displays the partitions.
 */
@Component({
  selector: 'partitions',
  templateUrl: './partitions.component.html',
  styleUrls: ['./partitions.component.scss']
})
export class PartitionsComponent implements OnInit {

  public partitions = [];
  // this variable decides whether to display the UI or if the request is still processing
  public isProcessing: boolean;

  constructor(public partitionService: PartitionService,
              public dialogService: DialogService) {
  }

  ngOnInit() {
    this.isProcessing = true;
    this.getPartitions();
  }

  /**
   * Get the list of partitions from server
   * The parameter is optional. It is sent only when a partition is deleted
   * The purpose of this parameter is to decide which progress dialog message to show
   * In case this is a reload after delete, do not show the Loading dialog as Deleting Partition dialog is already displayed
   * @param event
   */
  getPartitions(event?) {
    const dialogRef = event ? event.progressDialogRef : this.dialogService.progress({text: PROGRESS_DIALOG_MESSAGES.LOADING});
    this.partitionService.getPartitions().subscribe(res => {
      if (res && res.status === 200) {
        this.partitions = res.body;
        this.isProcessing = false;
        dialogRef.close();
      } else{
        this.isProcessing = false;
        dialogRef.close();
        this.dialogService.error(res);
      }
    }, err => {
      this.isProcessing = false;
      dialogRef.close();
      this.dialogService.error(err);
    });
  }

  hasPartitions(): boolean {
    return this.partitions.length > 0;
  }

  /** When the user clicks on 'Create Partition' button, it should open the Create Partition Wizard
   */
  openCreatePartitionDialog() {
    this.dialogService.open(CreatePartitionDialogComponent);
  }
}
