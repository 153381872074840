<footer class="fixed-bottom d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
        <img class="logo" src="images/footerlogo.png" alt="Logo"> Powered by Thales Data Protection on Demand
    </div>

    <nav class="links">
        <ul class="d-flex list-style-none">
            <li>
                <span class="wrap">
                    <a href="https://supportportal.thalesgroup.com/csm?id=kb_article_view&sys_kb_id=712b5d91dbe95344d298728dae9619f0&sysparm_article=KB0017432"
                        externalLink target="_blank" rel="noopener">Terms of Service</a>
                </span>
            </li>
            <li>
                <span class="wrap">
                    <a href="https://supportportal.thalesgroup.com/csm?id=kb_article_view&sys_kb_id=6646d191db295344d298728dae96199c&sysparm_article=KB0017428"
                        externalLink target="_blank" rel="noopener">Privacy Notice</a>
                </span>
            </li>
            <li>
                <span class="wrap">
                    <a href="{{docsService.getDocsLink()}}" externalLink target="_blank" rel="noopener">Help &amp; Documentation</a>
                </span>
            </li>
            <li>
                <span class="wrap">
                    <a href="https://status.dpondemand.io" externalLink target="_blank" rel="noopener">System Status</a>
                </span>
            </li>
            <li>
                <span class="wrap">&copy; {{currentYear}} Thales Group</span>
            </li>
        </ul>
    </nav>
</footer>
