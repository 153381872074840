import { Component, Input } from '@angular/core';
import { DEVICE_TYPE, UI_TEXT } from '../../partitions.config';

@Component({
  selector: 'review-partition',
  templateUrl: './review-partition.component.html',
  styleUrls: ['./review-partition.component.scss']
})
export class ReviewPartitionComponent {
  @Input() partitionName: string;
  @Input() deviceType: DEVICE_TYPE;
  readonly fipsRestrictions = UI_TEXT.FIPS_RESTRICTIONS;

  constructor() {
  }
}
