import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocsService {

  constructor() { }

  /**
   *  Takes the DOCS_BASE_URL and appends the path to it
   *  for dpod links, they should be prefixed with `/dpod` otherwise it'll link to other Thales products
   *
   *  Consider using `getDocsLinkByPortalId` if the ID exists in portal-links.json
   *
   *  @param [path] Path after the DOCS_BASE_URL, by default we will link to DPoD documentation
   *  @return absolute URL to documentation link
   */
  getDocsLink(path = '/dpod/'): string {
    // in local development, DOCS_BASE_URL will be undefined
    // passing in undefined to the URL function will throw an error
    // therefore we default to thalesdocs.com
    // this should only be an issue when developing and never on a public domain
    // const baseUrl = this.config.DOCS_BASE_URL ?? 'https://thalesdocs.com';
    const baseUrl = 'https://thalesdocs.com';
    return new URL(path, baseUrl).href;
  }
}
