import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PartitionService } from '../../services/partition.service';
import { PROGRESS_DIALOG_MESSAGES, TOAST_MESSAGES } from '../../partitions.config';
import { ToastService } from '../../../../shared/services/toast.service';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'create-client-dialog',
  templateUrl: './create-client-dialog.component.html',
  styleUrls: ['./create-client-dialog.component.scss']
})
export class CreateClientDialogComponent implements OnInit {
  buttonText = '';
  partitionClientName = '';
  isValid = false;
  @Output() public onCreate = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<CreateClientDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: DialogService,
              private partitionService: PartitionService,
              private toastService: ToastService) {
    this.buttonText = this.data.actionText;
  }

  ngOnInit() {}

  /**
   * This method handles the event changes for partition client name emitted by the child component
   * @param event
   */
  setPartitionClientName(event: any): void {
    ({name: this.partitionClientName, isValid: this.isValid} = event);
  }

  handleEvent(evt: string) {
    if (evt === 'confirm') {
      this.createPartitionClient();
    } else if (evt === 'cancel') {
      this.closeDialog();
    }
  }

  createPartitionClient() {
    const progressDialogRef = this.dialog.progress({text: PROGRESS_DIALOG_MESSAGES.CREATE_PARTITION_CLIENT});
    this.partitionService
          .postPartitionClient({partitionSerialNumber: this.data.serialNumber, name: this.partitionClientName})
          .subscribe(res => {
            if (res && res.status === 201){
              this.toastService.success(TOAST_MESSAGES.PARTITION_CLIENT_CREATED);
              this.onCreate.emit( {clientName: this.partitionClientName, clientId: res.body.clientId, clientSecret: res.body.clientSecret});
              progressDialogRef.close();
            } else {
              progressDialogRef.close();
              this.dialog.error(res);
            }
          }, err => {
            progressDialogRef.close();
            this.dialog.error(err);
          });
    this.closeDialog();
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
