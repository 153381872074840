<div class="gem-header section-header clients-header"> Partition Clients
</div>
<div *ngIf="isEmpty() && !isProcessing; else partitionClientsTable">
  <div class="empty-state-container">
    <div class="empty-state-header">
      Add a client to your partition
    </div>
    <div class="empty-state-text">
      Create a new partition client to get started. Follow the process to install your client and initialize your partition.
      <a class="empty-state-help" href="{{ clientsHelp.url | docLink }}" target="_blank" externalLink>Need help?</a>
    </div>
    <div>
      <img class="empty-state-img" src="images/clientEmptyState.svg" alt="envelope icon" role="presentation">
    </div>
    <div class="empty-state-btn-area">
      <button class="btn btn-next empty-state-btn" type="button" (click)="openCreatePartitionClientDialog()">Create Partition Client</button>
    </div>
  </div>
</div>

<ng-template #partitionClientsTable>
  <hsmod-table (filter)="onFilter($event)"
               [dataLength]="dataSource.data.length"
               [btnLabel]="'New Partition Client'"
               (createEvent)="openCreatePartitionClientDialog()">
    <table #table mat-table matSort [dataSource]="dataSource">
      <caption class="d-none">List of clients</caption>

      <!-- Client Name column -->
      <ng-container matColumnDef="name">
        <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let client">{{ client.name }}</td>
      </ng-container>

      <!-- Client Id column -->
      <ng-container matColumnDef="clientId">
        <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Client Id</th>
        <td mat-cell *matCellDef="let client">{{ client.clientId }}</td>
      </ng-container>

      <!-- Created At column -->
      <ng-container matColumnDef="createdAt">
        <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let client">{{ client.createdAt | formatDate }}</td>
      </ng-container>

      <!-- Created By column -->
      <ng-container matColumnDef="createdBy">
        <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
        <td mat-cell *matCellDef="let client">{{ client.createdBy }}</td>
      </ng-container>

      <!-- Actions column -->
      <ng-container matColumnDef="actions">
        <th scope="col" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let client" class="actions-column">
          <div class="action-cell" [attr.data-group-name]="client.name">
            <div class="icon-inside-icon">
              <span class="grid-action" (click)="openConfirmDelete(client.clientId)" data-locator="delete-client">
                  <svg class="icon-svg outer-icon">
                      <use xlink:href="#action-box" />
                  </svg>
                  <svg class="icon-svg inner-icon">
                      <use xlink:href="#trash" />
                  </svg>
              </span>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Rows definition -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let client; columns: displayedColumns;">
      </tr>
  </table>
</hsmod-table>
</ng-template>
