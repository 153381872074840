import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { PartitionsComponent } from './components/partitions.component';
import { AngularMaterialModule } from '../../shared/angular-material/angular-material.module';
import { CreatePartitionDialogComponent } from './components/create-partition-dialog/create-partition-dialog.component';
import { EmptyPartitionComponent } from './components/empty-partition/empty-partition.component';
import { SharedModule } from '../../shared/shared.module';
import { ConfigurePartitionComponent } from './components/configure-partition/configure-partition.component';
import { FormsModule } from '@angular/forms';
import { ReviewPartitionComponent } from './components/review-partition/review-partition.component';
import { PartitionListComponent } from './components/partition-list/partition-list.component';
import { DeletePartitionDialogComponent } from './components/delete-partition-dialog/delete-partition-dialog.component';
import { PartitionClientsComponent } from './components/partition-clients/partition-clients.component';
import { PartitionDetailsComponent } from './components/partition-details/partition-details.component';
import { CreateClientDialogComponent } from './components/create-client-dialog/create-client-dialog.component';
import { ConfigureClientComponent } from './components/configure-client/configure-client.component';
import { DownloadClientDialogComponent } from './components/download-client/download-client-dialog.component';

@NgModule({
  declarations: [
    PartitionsComponent,
    CreatePartitionDialogComponent,
    CreateClientDialogComponent,
    DownloadClientDialogComponent,
    EmptyPartitionComponent,
    ConfigurePartitionComponent,
    ConfigureClientComponent,
    PartitionListComponent,
    ReviewPartitionComponent,
    DeletePartitionDialogComponent,
    PartitionDetailsComponent,
    PartitionClientsComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    AngularMaterialModule,
    SharedModule,
    FormsModule
  ],
  exports: [
    PartitionsComponent,
  ]
})
export class PartitionsModule {
}
