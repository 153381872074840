<div class="buttons-container">
  <div class="buttons-section">
    <button id="clientDlg" class="btn btn-next" name="next" type="button" [disabled]="!isValid"
            (click)="btnEvent.emit('confirm')"> {{ buttonText }}
    </button>
  </div>
  <div>
    <button class="btn btn-default" name="cancel" type="button" (click)="btnEvent.emit('cancel')">Cancel</button>
  </div>
</div>
