import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { AuthService } from './auth.service';
import { JwtDecode, JwtDecodeProvider } from './jwt-decode';
import { OAuth2ClientJS, OAuth2ClientJSProvider } from './oauth2-client-js';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { environment } from '../../environments/environment';
import { MockAuthServiceProvider } from './auth.service.mock';
import { SessionExpiryService } from './session-expiry.service';
import { SessionExpiryInterceptor } from './session-expiry.interceptor';
import { HsmodUiConfig } from '../core/hsmod-ui-config';
import { HttpService } from '../services/HttpService';
import { CookieService } from 'ngx-cookie-service';
import { WindowToken } from '../shared/services/window';
import { ConfigToken } from '../config/config';
import { IAuthService } from './auth.interface';
import { AuthUAAService } from './auth-uaa.service';
import { UserManagerProvider, UserManagerToken } from './user-manager';
import { UserManager } from 'oidc-client-ts';
import { Router } from '@angular/router';

/**
 * A factory function that returns either OW or UAA AuthService.
 */
export function AuthServiceFactory(
  window: Window,
  oauth2: any,
  jwtDecode: any,
  config: HsmodUiConfig,
  httpService: HttpService,
  cookieService: CookieService,
  http: HttpClient,
  userManager: UserManager,
  router: Router
): IAuthService {
  if (config.FF_ONE_WELCOME) {
    return new AuthService(window, jwtDecode, config, httpService, cookieService, http, userManager, router);
  }
  return new AuthUAAService(window, oauth2, jwtDecode, config, httpService, cookieService, http);
}

/**
 * A custom provider that overrides AuthService and returns what AuthServiceFactory returns.
 * This solution isn't great - an ideal solution would be an Angular injection token.
 * But taking into account that the OW/UAA FF is temporary the overriding makes sense,
 * as it reduces the number of changes in hsmod-ui.
 * 
 * The provider and the factory above should be deleted after hsmod-ui uses only OW.
 */
const RealAuthServiceProvider: Provider = {
  provide: AuthService,
  useFactory: AuthServiceFactory,
  deps: [
    WindowToken,
    OAuth2ClientJS,
    JwtDecode,
    ConfigToken,
    HttpService,
    CookieService,
    HttpClient,
    UserManagerToken,
    Router
  ]
};

@NgModule({
  imports: [
    CommonModule,
  ],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        (
          environment.production
            ? RealAuthServiceProvider
            : MockAuthServiceProvider
        ),
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: Window, useValue: window },
        JwtDecodeProvider,
        OAuth2ClientJSProvider,
        UserManagerProvider,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },

        SessionExpiryService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SessionExpiryInterceptor,
          multi: true,
        },
      ],
    };
  }
}
