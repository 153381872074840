import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

// because we use our own component we therefore have to write this transition otherwise an error is thrown
export function flyInOut() {
  return trigger('flyInOut', [
    state('inactive', style({
      opacity: 0,
      display: 'flex',
    })),
    transition('inactive => active', animate('500ms 100ms ease-in-out', keyframes(
      [
        style({
          transform: 'translateY(175%)',
          opacity: 1
        }),
        style({
          transform: 'none',
          opacity: 1
        })
      ]
    ))),
    transition('* => void', animate('500ms 100ms ease-in-out', keyframes(
      [
        style({
          transform: 'none',
          opacity: 1
        }),
        style({
          transform: 'translateY(175%)',
          opacity: 1,
        })
      ]
    ))),
  ]);
}
