import { Component } from '@angular/core';

/**
 * This component displays the banner image
 * The purpose of having this as a separate component is we could change it back to how dpod-ui could retrieve the custom tenant logo
 */
@Component({
  selector: 'gem-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.scss']
})
export class BannerImageComponent {
}
