import { Pipe, PipeTransform } from '@angular/core';
/**
 * Purpose of the pipe is to always display the dates in the format "YYYY-MM-DD"
 * The input date format expected is "2021-10-04T15:43:55.445Z"
 * If the input format is different this code manually convert it to "2021-10-04T15:43:55.445Z" format and then extract the date
 * It returns only the date part to be displayed on the UI. For example "2021-10-04"
 */
@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string): string {
    // in case there is no value for date field
    if (!value){
      return '';
    }
    let formattedDate = value;
    if (formattedDate.indexOf('T') === -1){
      const onlyDate = new Date(Date.parse(value));
      formattedDate = onlyDate.toISOString(); // this always brings it to the format "2021-10-04T15:43:55.445Z"
    }
    return formattedDate.split('T')[0];
  }

}
