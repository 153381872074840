import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PartitionService } from '../../services/partition.service';
import {PROGRESS_DIALOG_MESSAGES, TOAST_MESSAGES} from "../../partitions.config";
import {DialogService} from "../../../../shared/services/dialog.service";
import {ToastService} from "../../../../shared/services/toast.service";
declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'create-client-dialog',
  templateUrl: './download-client-dialog.component.html',
  styleUrls: ['./download-client-dialog.component.scss']
})
export class DownloadClientDialogComponent implements OnInit{
  partitionClientName = '';
  isValid = false;
  buttonText = '';
  clientName: string;
  clientId: string;
  clientSecret; string;
  @Output() public onCreate = new EventEmitter<void>();

  constructor(public dialogRef: MatDialogRef<DownloadClientDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: DialogService,
              private partitionService: PartitionService,
              private toastService: ToastService) {
    if (data) {
      this.clientName = data.clientName;
      this.clientId = data.clientId;
      this.clientSecret = data.clientSecret;
      this.buttonText = this.data.actionText;
    }
  }

  ngOnInit() {}

  handleEvent(evt: string) {
    if (evt === 'confirm') {
      this.downloadClient();
    } else if (evt === 'cancel') {
      this.closeDialog();
    }
  }

  downloadClient() {
    const progressDialogRef = this.dialog.progress({text: PROGRESS_DIALOG_MESSAGES.DOWNLOAD_PARTITION_CLIENT});
    this.partitionService
      .downloadClient({clientId: this.clientId, clientSecret: this.clientSecret})
      .subscribe(res => {
        const blob = new Blob([res], { type: 'blob'});
        FileSaver.saveAs(blob, `setup-${this.clientName}.zip`);
        this.toastService.success(TOAST_MESSAGES.PARTITION_CLIENT_DOWNLOADED);
        progressDialogRef.close();
      }, err => {
        progressDialogRef.close();
        // TODO error handling
      });
    this.closeDialog();
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
