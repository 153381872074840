<h4 class="dialog-title">Create Partition Client</h4>
<div class="dialog-content">
  <div>
    <div class="main-instructions">
      <span>Generate a client package to connect your application to this partition.</span>
    </div>
  </div>

  <ng-container>
    <configure-client [partitionClientName]="partitionClientName"
                      (nameChangeEvent)="setPartitionClientName($event)">
    </configure-client>
  </ng-container>
</div>

<client-footer [isValid]="isValid"
               (btnEvent)="handleEvent($event)"
               [buttonText]="buttonText">
</client-footer>
