<h4 class="dialog-title"> Your client is ready </h4>
<div class="dialog-content">
  <div>
    <div class="main-instructions">
      <span>Use the button below to download your client</span>
    </div>
  </div>
</div>

<client-footer [isValid]="true"
               (btnEvent)="handleEvent($event)"
               [buttonText]="buttonText">
</client-footer>
