import { Component } from '@angular/core';

@Component({
  selector: 'session-logged-out',
  templateUrl: './session-logged-out.component.html',
  styleUrls: ['./session-logged-out.scss'],
})
export class SessionLoggedOutComponent {

  constructor() {}

  redirectToLogin() {
    window.location.href = '';
  }

}
