import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { HsmodTableComponent } from '../../../../components/hsmod-table/hsmod-table.component';
import { PARTITION_LIST_TB_COLS, PARTITION_ROUTES, TOAST_MESSAGES, UI_TEXT } from '../../partitions.config';
import { Router } from '@angular/router';
import { DialogService } from '../../../../shared/services/dialog.service';
import { PartitionService } from '../../services/partition.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'partition-list',
  templateUrl: './partition-list.component.html',
  styleUrls: ['./partition-list.component.scss']
})
export class PartitionListComponent implements OnChanges, AfterViewInit {
  @Input() partitionData;
  @Output() loadPartitions = new EventEmitter();

  dataSource = new MatTableDataSource();
  readonly displayedColumns = PARTITION_LIST_TB_COLS;
  readonly fipsRestrictions = UI_TEXT.FIPS_RESTRICTIONS;

  constructor(private router: Router,
              private dialogService: DialogService,
              private toastService: ToastService,
              private partitionService: PartitionService) { }

  @ViewChild(MatSort) private sort: MatSort;
  @ViewChild(HsmodTableComponent) private hsmodTable: HsmodTableComponent;

  ngOnChanges(): void {
    this.dataSource.data = this.partitionData || [];
  }

  // set the sort and paginator only after the view is rendered
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    if (this.hsmodTable) {
      this.dataSource.paginator = this.hsmodTable.paginator;
    }
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

  goToPartitionDetails(serialNumber: number){
    const partitionDetailsRoute = `/${PARTITION_ROUTES.basePath}/${serialNumber}`;
    this.router.navigate([partitionDetailsRoute]);
  }

  openConfirmDelete(partition) {
    this.partitionService.openConfirmDeletePartition(partition).subscribe(response => {
      // the error handling is done by the partition.service.ts method
      // since it is a common method for 2 screens. When it catches an error, it returns a null observable
      if (response) {
        const { res, progressDialogRef } = response;
        if (res && res.status === 204) {
          this.toastService.success(TOAST_MESSAGES.PARTITION_DELETED);
          this.loadPartitions.emit({ progressDialogRef });
        } else {
          progressDialogRef.close();
          this.dialogService.error(res?.body);
        }
      }
    });
  }
}
