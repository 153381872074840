<div class="err-hdr-container">
  <div class="err-image">
    <img src="images/dpod-icon-important-alert-yellow.svg" alt="">
  </div>
  <div class="err-hdr">
    Sorry!
  </div>
</div>
<div>
  <p class="err-header"> This service no longer exists or has been deactivated </p>
</div>
<div>
  <p class="err-msg">
    If you think this is an error please contact <a href="mailto: dpondemand@thalesgroup.com">dpondemand@thalesgroup.com</a>
  </p>
</div>
