import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'configure-client',
  templateUrl: './configure-client.component.html',
  styleUrls: ['./configure-client.component.scss']
})
export class ConfigureClientComponent implements OnInit {
  readonly nameMaxLength = 64;
  @Input() partitionClientName = '';
  @Output() nameChangeEvent = new EventEmitter();
  keyDownLen: number;
  keyUpLen: number;
  maxCharReached: boolean;
  clientNamePattern = '^[a-zA-Z0-9_-]{1,64}$';
  constructor() { }

  ngOnInit(): void {}

  /**
   * The changes to the partition client name field is updated to the parent component
   * @param isFormValid
   */
  nameChange(isFormValid: boolean) {
    this.nameChangeEvent.emit({name: this.partitionClientName, isValid: isFormValid});
  }

  onKeyDown() {
    this.keyDownLen = this.partitionClientName.length;
  }

  onKeyUp() {
    this.keyUpLen = this.partitionClientName.length;
    this.maxCharReached = this.partitionClientName.length === this.nameMaxLength && this.keyUpLen === this.keyDownLen;
  }

}
