import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'client-footer',
  templateUrl: './client-footer.component.html',
  styleUrls: ['./client-footer.component.scss']
})
export class ClientFooterComponent implements OnInit {
  @Input() isValid: boolean;
  @Input() buttonText: string;
  @Output() btnEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
