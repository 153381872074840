import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material';

@Component({
  selector: 'hsmod-table',
  templateUrl: './hsmod-table.component.html',
  styleUrls: ['./hsmod-table.component.scss'],
  encapsulation: ViewEncapsulation.None // this allows to apply common table style to child components without using ::ng-deep
})
export class HsmodTableComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * Emits when the user has filtered the table. Bind this event and use it
   * to filter your `dataSource`.
   */
  @Output() filter = new EventEmitter<string>();

  /**
   * Emits page events from the paginator.
   */
  @Output() page = new EventEmitter<PageEvent>();

  @Input() dataLength: number;

  /**
   * Optional text to display above table
   */
  @Input() description?: string;
  @Input() btnLabel?: string;
  @Output() createEvent = new EventEmitter();

  public filterText = '';
  constructor() { }

  onFilter() {
    this.filter.emit(this.filterText);
  }

  onCreate() {
    this.createEvent.emit();
  }

}
