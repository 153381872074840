<div>
  <div class="login-container">
    <div class="login-header">
      HSM on Demand
    </div>
    <div class="login-text">
      Enter your {{ DPOD_URL_HOST }} to log in. You can find this in your welcome email that was sent after registration.
    </div>
    <div class="login-text">
      <input id="uaaUrl" name="uaaUrl" type="text" #dPoDUrl placeholder="{{ DPOD_URL_HOST }}" class="form-control form-text" uaaUrl required
             (keyup.enter)="login()"
             (focus)="dPoDUrl.placeholder = ''"
             (blur)="dPoDUrl.placeholder = DPOD_URL_HOST"/>
    </div>
    <div class="login-btn-area">
      <button class="btn btn-next login-btn" type="button" (click)="login()">NEXT
      </button>
    </div>

    <div class="login-btn-area error" *ngIf="errMessage">{{errMessage}}</div>

    <div class="login-text">
    <span layout="row"><hr flex/></span>
    </div>

    <div class="login-text help-text">
      Don’t know your {{ DPOD_URL_HOST }}?  Contact <a href="mailto: dpondemand@thalesgroup.com">dpondemand@thalesgroup.com</a> to find it.
    </div>
  </div>
</div>

