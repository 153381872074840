import { Inject, Injectable } from '@angular/core';
import * as jwtDecodeLib from 'jwt-decode';
import { unverifiedScopeSuffix } from './auth.constants';
import { IAuthService, JWT, LoginAction, LoginState } from './auth.interface';
import { getSubscriberGroupId, getTenantId, isEmailVerified } from './auth.utils';
import { JwtDecode } from './jwt-decode';
import * as AuthScopes from './roles.constants';
import ValidStates from './valid-states.constants';
import { HttpService } from '../services/HttpService';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { ConfigToken } from '../config/config';
import { HsmodUiConfig } from '../core/hsmod-ui-config';
import { User, UserManager } from 'oidc-client-ts';
import { oneWelcomeResponseType, UserManagerToken } from './user-manager';
import { Router } from '@angular/router';

export const hsmodUiTokenKey = 'hsmodui-access_token';

/**
 * Creates an AuthService that works with OW.
 * The methods will be implemented later.
 */
@Injectable()
export class AuthService implements IAuthService {

  redirecting = false;
  constructor(
    private window: Window,
    @Inject(JwtDecode) private jwtDecode: JWTDecodeLib,
    @Inject(ConfigToken) public config: HsmodUiConfig,
    private httpService: HttpService,
    private cookieService: CookieService,
    private http: HttpClient,
    @Inject(UserManagerToken) private userManager: UserManager,
    private router: Router
  ) {
    this.init();
  }
  private identity = null;
  private scopes: readonly string[] = [];
  private hsmodUiUserKey: string;
  private user: User | null = null;

  // Helper function called only from the constructor
  init() {
    const authority = this.config.OW_AUTHORITY;
    const clientId = this.config.OW_CLIENT_ID;
    this.hsmodUiUserKey = `oidc.user:${authority}:${clientId}`;
    this.tryToRestoreUser();
  }

  /**
   * Determine whether the user is allowed to view the given UI-router state. In other words,
   * check whether the user has any scope for which the state is valid.
   */
  isStateValid(state): boolean {
    return Object.keys(AuthScopes).some((scopeName) => {
      const scope = AuthScopes[scopeName];
      return this.hasScope(scope) && (ValidStates[scopeName] || []).includes(state);
    });
  }

  /**
   * @returns Whether the user has verified their email address
   */
  isEmailVerified(): boolean {
    return isEmailVerified(this.scopes);
  }

  /**
   * @returns Whether we've started redirecting the user to the login or logout page.
   */
  isRedirecting(): boolean {
    return this.redirecting;
  }

  /**
   * @returns The encoded JWT (also called access_token)
   */
  getToken(): string {
    return this.user?.access_token;
  }

  /**
   * @returns The decoded JWT
   */
  getIdentity(): JWT {
    return this.identity;
  }

  /**
   * @returns The id of the tenant that the user is logged into.
   */
  getTenantId(): string {
    return getTenantId(this.scopes);
  }

  /**
   * @returns The id of the subscriber group that the user belongs to.
   * null is returned if the user doesn't belong to a subscriber group (e.g. if the user is an SP Admin or Operator)
   */
  getSubscriberGroupId(): string {
    return getSubscriberGroupId(this.scopes);
  }

  /**
   * todo verify that nothing is using this incorrectly as we also include unverified scopes
   * @returns `true` if the user has the given scope or an "unverified" variant thereof.
   */
  hasScope(scope: string): boolean {
    return this.scopes.includes(scope) || this.scopes.includes(scope + unverifiedScopeSuffix);
  }

  /**
   * Checks if one of the scopes exist for this user
   * use this when possible instead of `hasScope`
   * which also checks for `unverified` which may lead to something unintended
   * @param scopes  array of passed in scopes
   */
  hasAnyScope(...scopes: string[]): boolean {
    return !!this.scopes.find((userScope) => scopes.includes(userScope));
  }

  /**
   * @param returnTo The $location path to restore after login (default: current path)
   */
  async login(returnTo?: string) {
    if (this.redirecting) {
      return;
    }
    this.redirecting = true;
    try {
      await this.userManager.signinRedirect({ state: returnTo || this.getRedirectLocation() });
    } catch (error) {
      // Re-throwing the error to trigger the Angular global error handler
      this.handleLoginError(error);
    } finally {
      this.redirecting = false;
    }
  }

  async logout() {
    const idToken = this.user.id_token;
    this.redirecting = true;
    await this.reset();
    await this.userManager.signoutRedirect({
      state: this.getRedirectLocation(),
      post_logout_redirect_uri: this.window.location.origin,
      id_token_hint: idToken
    });
  }

  /**
   * Returns the AuthService to the "not logged in" state.
   */
  async reset() {
    this.deleteHsmodTokens();
    await this.userManager.clearStaleState();
    await this.userManager.removeUser();
    await this.userManager.revokeTokens();
  }

  redirectToHsmodUiState(state = '') {
    if (!this.isCodePresentInUrl()) {
      // If a code is not present in the URL the method has been called by the initiazer factory because of repeating ui-router transitions.
      // We ignore such calls - otherwise, the browser will redirect to the same URL repeatedly which will lead to console errors.
      return;
    }
    this.router.navigateByUrl(`/${state}`);
  }

  async determineLoginState(): Promise<LoginState> {
    if (this.user) {
      // The user object is present in the auth service; it means the user has been logged in
      return {
        loginAction: LoginAction.LoggedIn
      };
    }
    if (this.isCodePresentInUrl()) {
      // The URL has a code that can be exchanged for the user token
      const urlState = await this.fetchTokenAndState();
      if (this.user) {
        // The user object has just become present in the auth service; it means the user has just got the token
        return {
          loginAction: LoginAction.LoggedIn,
          hsmodUiState: urlState // Redirect the user to the restored hsmod-ui state
        };
      }
    }
    // The user is not and cannot be logged in
    return {
      loginAction: LoginAction.OwRedirect
    };
  }

  getTenantMetadata(serviceId: string): Observable<any> {
    const apiUrl = 'info';
    return this.httpService.get(`${apiUrl}`, `dashboard_id=${serviceId}`);
  }

  validateUrl(uaaUrl: string): Observable<any> {
    return this.http.get(uaaUrl);
  }

  /**
   * @returns The URI where OW should redirect back to after the user logs in.
   */
  private getRedirectLocation(): string {
    const path = this.window.location.pathname;
    return path ? path.substring(1) : '';
  }

  private tryToRestoreUser() {
    const hsmodUiUserString = this.window.localStorage.getItem(this.hsmodUiUserKey);
    if (hsmodUiUserString) {
      try {
        const hsmodUiUser = JSON.parse(hsmodUiUserString);
        this.user = new User(hsmodUiUser);
        this.storeHsmodTokens();
      } catch (error) {
        // The token is invalid; we can just ignore it - the user is not authenticated
        console.error('Invalid token', error);
      }
    }
  }

  private isCodePresentInUrl() {
    const args = new URLSearchParams(this.window.location.search);
    return !!args.get(oneWelcomeResponseType);
  }

  private async fetchTokenAndState(): Promise<string | undefined> {
    const user = await this.userManager.signinCallback();
    if (!user) {
      return;
    }
    this.user = user;
    this.storeHsmodTokens();
    return this.user.state as string || '';
  }

  private storeHsmodTokens() {
    this.identity = this.jwtDecode.jwtDecode(this.user.access_token);
    // Take email from user profile - the email is absent in the access token
    this.identity.email = this.user.profile.email;
    this.scopes = this.identity.authorities;
    this.window.localStorage.setItem(hsmodUiTokenKey, this.user.access_token);
  }

  private deleteHsmodTokens() {
    this.window.localStorage.removeItem(this.hsmodUiUserKey);
    this.window.localStorage.removeItem(hsmodUiTokenKey);
    this.user = null;
    this.identity = null;
    this.scopes = [];
  }

  // The following code is encapsulated into a method only because of unit tests
  private handleLoginError(error: Error) {
    // Re-throwing the error to trigger the Angular global error handler
    throw error;
  }
}

// Can't seem to directly reference this as a type from jwt-decode library
interface JWTDecodeLib {
  jwtDecode: (token: string, options?: jwtDecodeLib.JwtDecodeOptions) => JWT;
}
