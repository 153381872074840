import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable()
export class HttpService {

  protected BASE_URL = '/v1/';

  constructor(private http: HttpClient) {
  }

  private accessForbidden = new BehaviorSubject(false);

  private readonly REQUEST_HEADERS = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});

  /**
   * Performs a request with `get` http method.
   */
  public get<T>(uri: string, queryString?: string): Observable<HttpResponse<T>> {
    const fullURL: string = this.getFullURL(uri, queryString);
    return this.http.get<any>(fullURL, {headers: this.REQUEST_HEADERS, observe : 'response'});
  }

  /**
   * Performs a request with `post` http method.
   */
  public post<T>(uri: string, body: object, queryString?: string): Observable<HttpResponse<T>> {
    const fullURL: string = this.getFullURL(uri, queryString);
    return this.http.post<T>(fullURL, body, {observe: 'response'});
  }

  /**
   * Performs a request with `post` http method to handle file in response.
   */
  public postForFile<T>(uri: string, body: object, queryString?: string): Observable<any> {
    const fullURL: string = this.getFullURL(uri, queryString);
    return this.http.post(fullURL, body, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  /**
   * Performs a request with `delete` http method.
   */
  public delete<T>(uri: string, queryString?: string): Observable<HttpResponse<T>> {
    const fullURL: string = this.getFullURL(uri, queryString);
    return this.http.delete<T>(fullURL, {observe: 'response'});
  }

  public getAccessForbidden() {
    return this.accessForbidden;
  }
  public setAccessForbidden(isForbidden){
    this.accessForbidden.next(isForbidden);
  }

  // this is purposefully written as an arrow function to avoid `this` binding
  handleForbiddenAccess = (err) => {
    if (err.status === 403) {
      this.setAccessForbidden(true);
    }
    return of(err);
  }

  private getFullURL(uri: string, queryString?: string): string {
    let result = this.BASE_URL + uri;
    if (queryString) {
      result = `${result}?${queryString}`;
    }
    return result;
  }
}
