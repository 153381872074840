import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import * as Roles from '../../auth/roles.constants';
import { CookieService } from 'ngx-cookie-service';
import { ConfigToken } from '../../config/config';
import { HsmodUiConfig } from '../../core/hsmod-ui-config';

interface MenuItem {
  text: string;
  action: () => void;
  locator: string;
  requiredRoles?: string;
}

@Component({
  selector: 'gem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userName = '';
  userRole = '';
  parentName = '';
  isSubscriber = false;
  dpodUrl;

  readonly MENU_OPTIONS: MenuItem[] = [{
    text: 'Sign Out',
    action: this.authService.logout.bind(this.authService),
    locator: 'logout',
  },
  ];

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    @Inject(ConfigToken) public config: HsmodUiConfig,
  ) {
  }

  ngOnInit() {
    this.setIdentity();
  }

  setIdentity() {
    const identity = this.authService.getIdentity();
    if (!identity) {
      return;
    }
    this.userName = identity.email;
    this.userRole = this.getRoleLabel();
    this.constructDpodUrl();
  }

  getRoleLabel(): string {
    const authService = this.authService;
    const scopes = Roles;
    switch (true) {
      case authService.hasScope(scopes.admin):
        return 'Tenant Administrator';
      case authService.hasScope(scopes.owner):
        return 'Application Owner';
      case authService.hasScope(scopes.spadmin):
        return 'Service Provider Admin';
      case authService.hasScope(scopes.operator):
        return 'DPoD Operator';
    }
    return ''; // should not happen
  }

  /**
   * Filter the menu options based on role if required
   */
  getUserActions(): MenuItem[] {
    return this.MENU_OPTIONS;
  }

  constructDpodUrl() {
    if (this.config.FF_ONE_WELCOME) {
      this.dpodUrl = `https://${this.config.DPOD_DOMAIN}`;
      return;
    }
    const tenantMetadata = this.cookieService.get('tenant.metadata');
    if (tenantMetadata) {
      const loginUrl = `${JSON.parse(tenantMetadata)?.url}`;
      const tenantName = this.getTenantName(loginUrl);
      if (tenantName) {
        this.dpodUrl = `https://${tenantName}.${this.config.GEM_APPSDOMAIN}`;
      }
    }
  }

  getTenantName(url: string) {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2].split('.')[0];
    }
    return '';
  }


}
